.qty {
	display: flex;
	align-items: center;
	justify-content: center;

	padding: 4px 8px;
	box-sizing: border-box;
	position: absolute;
	right: 8px;
	top: 8px;
	border-radius: 20px;
	background: rgba(0, 0, 0, 0.3);
	backdrop-filter: blur(20px);

	margin-top: 0;
	z-index: 2;
}

.qty :global(.messages-qty__text) {
	color: rgba(255, 255, 255, 0.5);
	font-family: Inter;
	font-size: 10px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin: 0;
}

.qty :global(.messages-qty__bubble) {
	width: 10px;
	height: 10px;
	margin-right: 4px;
}

.container {
	width: 190px;
	height: 300px;
	box-sizing: border-box;
	flex-shrink: 0;
	padding: 14px;
	text-decoration: none;
	border-radius: 32px;
	border: 1px solid rgba(255, 255, 255, 0.02);
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	gap: 10px;
	margin-left: auto;
	margin-right: auto;
}

.photo {
	width: 24px;
	height: 24px;
	flex-shrink: 0;
	border-radius: 32px;
	border: 1px solid rgba(255, 255, 255, 0.2);
}

.name {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	line-clamp: 2;
	flex: 1 0 0;
	overflow: hidden;
	color: #fff;
	text-overflow: ellipsis;
	text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.45);
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 800;
	line-height: 24px; /* 150% */
	padding: 0;
	margin: 0;
}

.namePhotoRow {
	display: flex;
	align-items: flex-start;
	gap: 8px;
	z-index: 2;
}

.greeting {
	display: none;
}

.container:hover .greeting {
	display: block;
	align-self: stretch;
	color: #cac8f2;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 21px; /* 150% */
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 6;
	line-clamp: 6;
	align-self: stretch;
	z-index: 2;
	overflow: hidden;
}

.replyBtn {
	display: none;
}

.container:hover .replyBtn {
	margin-top: 16px;
	display: flex;
	width: 100%;
	padding: 8px 24px;
	box-sizing: border-box;
	justify-content: center;
	align-items: center;
	gap: 10px;

	border-radius: 30px;
	background: #7b5df3;
	z-index: 2;
}

.replyBtnText {
	color: #fff;
	font-family: Inter;
	font-size: 15px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px; /* 133.333% */
}

.overlay {
	position: absolute;
	bottom: -2px;
	left: 0;
	width: 101%;
	height: 101%;
	background: linear-gradient(180deg, #0c0c0c6a 0%, #0a0a0a 90%);
	z-index: 0;
	opacity: 0;
	transition: opacity 0.3s ease;
	border-bottom-left-radius: 28px;
	border-bottom-right-radius: 28px;
}

.container:hover .overlay {
	opacity: 1;
}

@media (max-width: 960px) {
	.container {
		width: 154px;
		height: 240px;
	}

	.photo {
		display: none;
	}

	.overlay {
		opacity: 1;
		bottom: -2px;
		background: linear-gradient(
			180deg,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0) 50%,
			#0a0a0a 90%
		);
	}

	.greeting {
		display: block;
		align-self: stretch;
		color: #cac8f2;
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 21px; /* 150% */
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		line-clamp: 2;
		align-self: stretch;
		z-index: 2;
		overflow: hidden;
	}

	.container:hover .greeting {
		display: block;
		align-self: stretch;
		color: #cac8f2;
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 21px; /* 150% */
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		line-clamp: 2;
		align-self: stretch;
		z-index: 2;
		overflow: hidden;
	}

	.container:hover .replyBtn {
		display: none;
	}
}
