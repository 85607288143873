.addButton:global(.inbutton) {
	margin-top: 32px;
	width: 100%;
	height: 64px !important;
	border-radius: 100px !important;
	margin-top: 32px !important;
	background: #5a34f0 !important;
	font-family: 'Inter', sans-serif !important;
}

.modal {
	max-width: 720px;
	height: fit-content;
	margin: auto;
	border-radius: 20px;
	background: rgba(41, 40, 65, 1);
	box-sizing: border-box;
}

.modalContent {
	display: flex;

	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 40px;
	gap: 24px;
}

.title {
	color: #fff;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px; /* 150% */
	letter-spacing: 0.4px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex: 1 0 0;
	align-self: stretch;
}

.icon {
	width: 64px;
	height: 64px;
}

.header {
	display: flex;
	padding: 24px;
	align-items: center;
	gap: 20px;
	align-self: stretch;
	border-radius: 14px;
	border: 0px solid var(--Dark-Third-Bg, #2e2c46);
	background: #222134;
	box-sizing: border-box;
}

.modalTitle {
	color: #fff;
	text-align: center;
	font-family: Inter;
	font-size: 32px;
	font-style: normal;
	font-weight: 900;
	line-height: 48px; /* 150% */
	letter-spacing: 0.374px;
	margin: 0;
}

.personality {
	margin-top: 0px;
}

.personality h3 {
	color: #8b8aab;
}

.namePronoun {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
}

.namePronounTitle {
	font-size: 16px;
	color: #8b8aab;
	font-weight: 700;
}

.avatarSelector {
	width: 100%;
	justify-content: flex-start;
}

.pairTooltip {
	position: absolute;
	top: -20px;
	left: 35%;
	transform: translate(-50%, -50%);
	padding: 16px 20px;
	background: #5a34f0;
	border-radius: 10px;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	color: var(--Dark-Text-Normal, #e2dfef);
}

.pairTooltip::after {
	content: '';
	position: absolute;
	bottom: -8px;
	left: 50%;
	transform: translateX(-50%);
	width: 0;
	height: 0;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-top: 8px solid #5a34f0;
}

.avatarContainer {
	width: 100%;
	position: relative;
}

.mobilePronounContainer {
	width: 100%;
}

.pronoun {
	margin-top: 0px;
	border-radius: 14px !important;
	background: #12121b;
	padding: 4px;
	box-sizing: border-box;
	width: 100%;
}

.btn:global(.MuiButtonBase-root) {
	padding: 12px 30px;
	color: #9c85f6;

	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 18px; /* 128.571% */
	letter-spacing: 0.1px;
	background: #12121b;
	border: none;
	width: 100%;
}
.btn:global(.MuiButtonBase-root):hover {
	border: none;
}

.btnSelected:global(.MuiButtonBase-root) {
	border-radius: 10px !important;
	background: #292841 !important;
	color: #fff;
}

.error {
	color: #ff4d4f;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0.1px;
	margin-top: 4px;
	margin-bottom: 0;
	text-align: start;
}

.btn:global(.MuiButtonBase-root):disabled {
	background-color: #1a1928;
	color: #3f3d56;
	cursor: not-allowed;
	border: 1px solid #3f3d56;
}

.btnSelected:global(.MuiButtonBase-root):disabled {
	background-color: #1a1928 !important;
	color: #3f3d56;
	cursor: not-allowed;
	border: 1px solid #3f3d56;
}

@media (max-width: 960px) {
	.header {
		flex-direction: column;
	}
	.personality {
		margin-top: 24px;
	}

	.pronoun {
		margin-top: 24px;
	}

	.avatarSelector {
		margin-top: 24px;
	}
}
