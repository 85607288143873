.main {
	--margin: 20px;
	min-height: 100dvh;
	display: flex;
	flex-direction: column;
}

.upper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.main__create-btn {
	margin-top: 28px !important;
}

.main-container {
	display: flex;
	flex-direction: row;
}

.main-desktop {
	height: 100dvh;
}

.main-content-container {
	width: calc(100% - var(--sidebar-width));
}

.main-content-desk {
	position: relative;
	margin: 0 auto;
	max-width: 1240px;
	height: calc(100dvh - 64px);
	overflow-y: auto;
	padding: 0 40px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
}

@media screen and (min-width: 1550px) {
	.main-content-desk {
		padding: 0;
	}
}

.main-content-desk::-webkit-scrollbar {
	width: 0px;
	height: 0px;
}

.main-content-desk::after {
	content: '';
	position: fixed;
	bottom: -3px;
	width: calc(100% - var(--sidebar-width));
	right: 0;
	height: 80px;
	background: linear-gradient(
		180deg,
		rgba(25, 24, 39, 0) 0%,
		rgba(25, 24, 39, 0.99) 84.17%
	);
	pointer-events: none;
	display: none;
}

.main-content-desk:not(.nofade)::after {
	display: block;
}
