.headerContent {
	color: #5a5976;

	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 142.857% */
	letter-spacing: 0.374px;
	margin: 20px 0 0 20px;
	display: flex;
	width: calc(100% - 20px);
}

.header {
	display: flex;
	flex-direction: column;
	width: 100dvw;
	margin-top: 24px;
	box-sizing: border-box;
}

.createBtn {
	margin: auto !important;
}

.getAccessBtn {
	margin: auto;
	margin-bottom: 20px;
	width: calc(100% - 40px) !important;
}

@media screen and (min-width: 960px) {
	.headerContent {
		margin: 20px 0 0 0;
		width: 100%;
	}
}

.headerContent a {
	color: #5a5976;
	text-decoration-line: underline;
	padding: 0;
}

.headerContent p {
	margin: 0 10px;
	padding: 0;
}

.visited {
	--wa: 64px;
	padding: 0 20px 20px 20px;
	box-sizing: border-box;
}

.visitedHeader {
	color: #fff;
	font-family: Inter;
	font-size: 28px;
	font-style: normal;
	font-weight: 900;
	line-height: 36px; /* 128.571% */
	letter-spacing: 0.374px;
}

.visitedRow {
	display: flex;
	width: 100%;
	margin-top: 20px;
	cursor: pointer;
}

.visitedAvatar {
	width: var(--wa);
	height: var(--wa);
	border-radius: 10px;
}

.visitedText {
	display: flex;
	flex-direction: column;
	margin-left: 16px;
	justify-content: center;
	align-items: flex-start;
	width: calc(100% - var(--wa) - 16px);
}

.visitedText p {
	margin: 0;
	padding: 0;
}

.visitedName {
	color: #fff;
	text-align: center;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px; /* 150% */
	letter-spacing: 0.2px;
}

.visitedMessage {
	overflow: hidden;
	color: #737191;
	text-overflow: ellipsis;
	text-wrap: nowrap;
	font-family: Inter;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 133.333% */
	letter-spacing: 0.15px;
	margin-top: 4px !important;
	width: 100%;
	white-space: nowrap;
}
