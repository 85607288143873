.deleteText {
	color: #ff4d4d !important;
}

.dialog :global(.MuiDialog-paper) {
	background-color: #171722;
	color: #ffffff;
	fill: linear-gradient(0deg, #212034 0%, #212034 100%), #f1eeff;
	stroke-width: 3px;
	stroke: rgba(255, 255, 255, 0.08);
	padding: 40px 24px;
	border-radius: 24px;
	border: 3px solid rgba(255, 255, 255, 0.08);
}

.dialog :global(.MuiDialogTitle-root) {
	color: #ffffff;
}

.dialog :global(.MuiTypography-root) {
	color: #fff;
	text-align: center;
	font-family: Inter;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px; /* 160% */
	letter-spacing: 0.374px;
}

.dialog :global(.MuiDialogContentText-root) {
	margin-top: 24px;
}

.dialog :global(.MuiTypography-h6) {
	color: #fff;
	text-align: center;
	font-family: Inter;
	font-size: 28px;
	font-style: normal;
	font-weight: 900;
	line-height: 36px; /* 128.571% */
	letter-spacing: 0.374px;
}

.dialog :global(.MuiButton-root.MuiButtonBase-root) {
	color: #ffffff;
	display: flex;
	padding: 16px 32px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	flex: 1 0 0;
	box-sizing: border-box;
	width: 100%;
	color: #9c85f6;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 900;
	line-height: 24px; /* 150% */
	letter-spacing: 0.2px;
	border-radius: 40px;
}
.dialog :global(.MuiButton-root.MuiButtonBase-root).dialogButtonCancel {
	background: #282649;
}

.dialog :global(.MuiButton-root.MuiButtonBase-root).dialogButtonDelete {
	background: #fd471e;
	color: #fff;
}

.dialog :global(.MuiDialogActions-root) {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
	flex: 1 0 0;
	box-sizing: border-box;
	margin-top: 32px;
}

@media screen and (max-width: 768px) {
	.dialog :global(.MuiDialogContent-root) {
		padding: 0;
	}
}
