.success-payment {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 50vh;
	margin-top: 200px;
}

.success-paymnet__text {
	font-size: 40px;
	font-weight: 800;
	line-height: 44px;
}

.success-payment__check.MuiSvgIcon-root {
	color: green;
	margin: auto;
	width: 156px;
	height: 156px;
}
