.create-btn.inbutton.MuiButton-root {
	width: calc(100% - var(--margin) * 2);
	height: 56px;
}

.create-btn.inbutton.MuiButton-root p {
	padding: 0;
	margin-left: 12px;
	font-size: 16px;
}
