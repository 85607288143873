.App {
	background: linear-gradient(0deg, #191827 0%, #191827 100%), #f1eeff;
	min-height: 100dvh;

	font-family: Inter;
}

:root {
	--main-color: #7b5df3;
}

.App .inbutton.MuiButton-root {
	font-family: Inter;
	color: white;
}

.success-payment {
	color: white;
}

.success-payment .inbutton {
	font-family: Inter !important;
}
