.avatarSelector {
	display: flex;
	justify-content: center;
	position: relative;
	height: 124px;
}

.fileInput {
	display: none;
}

.right {
	display: flex;
	flex-direction: column;
	margin-left: 30px;
	align-items: flex-start;
	justify-content: center;
}

.avatar {
	width: 120px;
	height: 120px;
	border-radius: 12px;
}

.avatarError {
	outline: 2px solid red;
}

.btn:global(.MuiButton-root) {
	color: #9c85f6;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px; /* 125% */
	letter-spacing: 0.1px;
	text-decoration-line: underline;

	text-transform: none;
}

.img {
	width: 20px;
	height: 20px;
	margin-right: 16px;
}

.row {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.row:not(:last-child) {
	margin-bottom: 30px;
}

.avatar-selector .chat__avatar {
	width: 200px;
	aspect-ratio: 1/1;
	height: auto;
	position: initial;
}

.avatar-selector__img {
	z-index: 1;
	position: absolute;
	background-color: rgb(0, 0, 0, 0.4);
	border-radius: 18px;
	cursor: pointer;
}

.avatar-selector__img_edit {
	bottom: 20px;
	right: 20px;
}

.avatar-selector__img_retry {
	bottom: 20px;
	left: 20px;
}

.talking-heads__photo-placeholder_error {
	outline: 3px solid red;
	border-radius: 20px !important;
}
