p {
	margin: 0;
	padding: 0;
}

.retryFeedbackRow {
	display: flex;
	padding: 16px 20px;
	align-items: center;
	gap: 16px;
	align-self: stretch;
	border-radius: 16px;
	border: 1px solid #38375a;
	box-sizing: border-box;
	margin: 12px 0 0 0;
	max-width: calc(90% + 20px);
}

.text {
	color: #cac8f2;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
	/* 150% */
}

.btns {
	display: flex;
	align-items: center;
	gap: 12px;
}

.btn {
	display: flex;
	padding: 10px 20px;
	justify-content: center;
	align-items: center;
	gap: 6px;
	box-sizing: border-box;
	border-radius: 24px;
	border: 1px solid #9c85f6;
	cursor: pointer;
}

.closeBtn {
	cursor: pointer;
}

.btnText {
	color: #9c85f6;
	text-align: center;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
	/* 125% */
	letter-spacing: 0.1px;
}

.textAndBtns {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

@media screen and (max-width: 758px) {
	.textAndBtns {
		flex-direction: column;
		gap: 16px;
		align-items: flex-start;
	}

	.retryFeedbackRow {
		align-items: flex-start;
		display: flex;
		padding: 16px;
		gap: 16px;
		position: relative;
	}

	.text {
		font-size: 16px;
		line-height: 24px;
		/* 150% */
	}

	.btn {
		padding: 8px 16px;
		gap: 6px;
	}

	.btnText {
		font-size: 14px;
		line-height: 18px;
		letter-spacing: 0.1px;
	}

	.btns {
		gap: 3px;
	}

	.closeBtn {
		position: absolute;
		top: 16px;
		right: 12px;
	}
}
