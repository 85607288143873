/* containerForScroll */
.containerForScroll {
	position: relative;
}

/* scroll */
.scroll {
	overflow-x: scroll;
	min-height: 46px;
	padding: 0;
	overflow-y: hidden;
}

.scroll::-webkit-scrollbar {
	width: 0px;
	height: 0px;
}

.scrollButton {
	position: absolute;
	width: 36px;
	height: 48px;
	z-index: 100;
	bottom: 50%;
	border-radius: 16px;
	background: #191827;
	color: #9c85f6;
	border: 2px solid #9c85f6;
	font-family: Inter;
	font-size: 15px;
	font-style: normal;
	cursor: pointer;
	top: 50%;
	transform: translateY(-50%);
}

.scrollButtonLeft {
	left: 0px;
	box-shadow: 10px 0px 10px rgba(0, 0, 0, 0.2);
}

.scrollButtonLeft img {
	transform: rotate(90deg);
}

.scrollButtonRight {
	right: 0px;
	box-shadow: 10px 0px 10px rgba(0, 0, 0, 0.2);
}

.scrollButtonRight::after {
	content: '';
	position: absolute;
	top: -94px;
	width: 100%;
	left: 10px;
	height: 330px;
	background: linear-gradient(
		90deg,
		rgba(25, 24, 39, 0) 0%,
		rgba(25, 24, 39, 1) 80%
	);
	pointer-events: none;
	max-width: 80px;
}

.scrollButtonLeft::after {
	content: '';
	position: absolute;
	top: -94px;
	width: 100%;
	right: 10px;
	height: 330px;
	background: linear-gradient(
		270deg,
		rgba(25, 24, 39, 0) 0%,
		rgba(25, 24, 39, 1) 80%
	);
	pointer-events: none;
	max-width: 80px;
}

.scrollButtonRight img {
	transform: rotate(-90deg);
}

@media screen and (max-width: 960px) {
	.scroll::-webkit-scrollbar {
		display: none;
	}
}
