.deleteButton {
	color: #ff4d4d !important;
}

.dialog :global(.MuiDialog-paper) {
	background-color: #292841;
	color: #ffffff;
}

.dialog :global(.MuiDialogTitle-root) {
	color: #ffffff;
}

.dialog :global(.MuiTypography-root) {
	color: #ffffff;
}

.dialogButton :global(.MuiButton-root) {
	color: #ffffff;
}
