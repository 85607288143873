.profile {
	width: 40px;
	height: 40px;
	flex-shrink: 0;
	position: relative;
	border-radius: 40px;
	background: linear-gradient(0deg, #191928 0%, #191928 100%),
		lightgray 50% / cover no-repeat;
	margin-left: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.userIcon {
	margin: auto;
	width: 36px;
	height: 36px;
	border-radius: 36px;
}

.popover {
	position: absolute;
	border-radius: 10px;
	background: #292841;
	box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.45);
	top: 60px;
	right: 0px;
	width: 232px;
	z-index: 101;
}

.row {
	display: flex;
	padding: 12px 20px;
	text-decoration: none;
}

.row:not(:last-child) {
	border-bottom: 1px solid #1c1c24;
}

.icon {
}

.text {
	margin: 0;
	padding: 0;
	color: #9c85f6;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	/* 150% */
	margin-left: 12px;
	cursor: pointer;
}

.deleteText {
	color: #ff4d4d;
}

.dialog :global(.MuiDialog-paper) {
	background-color: #292841;
	color: #ffffff;
}

.dialog :global(.MuiDialogTitle-root) {
	color: #ffffff;
}

.dialog :global(.MuiTypography-root) {
	color: #ffffff;
}

.dialogButton :global(.MuiButton-root) {
	color: #ffffff;
}
