.modal {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	border-radius: 30px;
	background-color: white;

	align-items: flex-start;
	overflow: hidden;
	background: linear-gradient(0deg, #212034 0%, #212034 100%), #f1eeff;
	font-family: Inter;
	width: 800px;
	height: 432px;
}

.dialog :global(.MuiPaper-root) {
	background: linear-gradient(0deg, #191827 0%, #191827 100%), #f1eeff;
	display: flex;
	flex-direction: column;
	font-family: 'Inter';
	align-items: center;
	color: white;
	text-align: center;
	width: 100%;
}

.dialog :global(.inbutton) {
	font-family: Inter;
	margin-top: 0 !important;
	margin: 0;
}

.container {
	max-width: 720px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
}

.header {
	display: flex;
	width: 100%;
	margin-top: 40px;
	justify-content: center;
	position: relative;
	border-bottom: 1px solid #3f3e62;
}

.back {
	position: absolute;
	left: 0;
	top: 8px;
}

.title {
	color: #fff;
	text-align: center;
	font-family: Inter;
	font-size: 32px;
	font-style: normal;
	font-weight: 900;
	line-height: 48px; /* 150% */
	letter-spacing: 0.374px;
	margin: 0;
	padding-bottom: 40px;
}

.block {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	margin-top: 40px;
	width: 100%;
}

.oneLine {
	height: 60px !important;
}

.blockTitle {
	color: #fff;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px; /* 150% */
	letter-spacing: 0.374px;
	margin: 0 0 16px 0;
}

.blockContent {
	width: 100%;
}

.avatarBlock {
	display: flex;
}

.settingsRow {
	display: grid;
	gap: 40px;
	grid-template-columns: repeat(1, 1fr);
}

.settingsRow :global(.voice-selector-row) {
	background: #212034;
	font-family: Inter;
	cursor: pointer;
}

.settingsRow :global(.voice-selector-row__container) {
	background: #212034;
}

.settingsRow :global(.voice-type-switcher) {
	background: #212034;
}

.settingsRow :global(.voice-type-switcher) :global(.option_selected) {
	background: #5a5976;
}

.settingsRow :global(.voice-type-switcher) :global(.option) {
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px; /* 125% */
	letter-spacing: 0.1px;
	height: 36px;
	box-sizing: border-box;
}

.settingsRow :global(.voice-type-switcher) :global(.option) :global(.text) {
	color: #9c85f6;
}

.settingsRow
	:global(.voice-type-switcher)
	:global(.option_selected)
	:global(.text) {
	color: #fff;
}

.createButton:global(.inbutton) {
	width: 100%;
	margin-top: 40px !important;
	border-radius: 34px !important;
	margin-bottom: 80px;
}

.back {
	cursor: pointer;
}

.public {
	display: flex;
	flex-direction: column;
}
