.modal :global(.paywall-modal__inner) {
	background-image: url('../images/ultra-bg.webp');
	border-radius: 32px;
	background-size: cover;
}

.header {
	display: flex;
	gap: 8px;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-top: 60px;
}

.ultra {
	height: 40px;
}

.priceBlock {
	height: calc(60px + 2 * 24);
	display: flex;
	padding: 20px 24px;
	box-sizing: border-box;
	flex-direction: column;
	align-items: flex-start;
	gap: 4px;
	border-radius: 16px;
	border: 1px solid rgba(255, 255, 255, 0.1);
	width: 100%;
	margin-top: 30px;
	cursor: pointer;
}

.price {
	color: #fff;
	text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.45);
	font-family: Inter;
	font-size: 28px;
	font-style: normal;
	font-weight: 700;
	line-height: 36px;
	/* 128.571% */
	margin: 0;
	padding: 0;
}

.pricespan {
	color: #fff;
	font-family: Inter;
	font-size: 16px;
	text-wrap: nowrap;
	font-style: normal;
	font-weight: 400;
	line-height: 36px;
	margin: 0;
	padding: 0;
	display: inline-block;
}

.subprice {
	color: #fff;
	text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.45);
	font-family: Inter;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px; /* 138.462% */
	/* 138.462% */
	opacity: 0.5;
	margin-top: 8px;
}

.btn:global(.inbutton) {
	border-radius: 14px;
	background: linear-gradient(90deg, #6943ff 28%, #5092ff 60.87%, #00d1ff 100%),
		#7b5df3;
}

.selected {
	border: 1px solid #fff;
}

.pricesBlock {
	display: flex;
	flex-direction: row;
	gap: 16px;
}

.upgrade {
	text-align: center;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: -0.3px;
	margin-top: 16px;
}
