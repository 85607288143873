.row {
	display: flex;
	margin-top: 16px;
	width: 100%;
	overflow: scroll;
	padding-right: 20px;
	box-sizing: border-box;
	padding-bottom: 4px;
	margin-left: 20px;
}

.rowWithRate {
	margin-left: 0;
	padding-right: 0;
}

.row::-webkit-scrollbar {
	width: 0px;
	height: 0px;
}

.tile {
	margin-right: 10px !important;
}

.grid {
	display: grid;
	justify-content: center;
	margin: 0 auto;
	grid-template-columns: repeat(4, 180px);
	row-gap: 16px;
	box-sizing: border-box;
	padding-bottom: 4px;
	justify-content: space-around;
}

.gridWithRate {
	grid-template-columns: repeat(4, 190px);
}

.mobileGrid {
	display: flex;
	flex-direction: column;
	padding: 0 20px;
}

@media screen and (max-width: 850px) {
	.grid {
		grid-template-columns: repeat(6, 96px);
	}
}

@media screen and (max-width: 680px) {
	.grid {
		grid-template-columns: repeat(5, 96px);
	}
}

@media screen and (max-width: 570px) {
	.grid {
		grid-template-columns: repeat(4, 96px);
	}
}

@media screen and (max-width: 460px) {
	.grid {
		grid-template-columns: repeat(3, 96px);
	}
}

@media screen and (max-width: 356px) {
	.grid {
		grid-template-columns: repeat(2, 96px);
	}
}

.container {
	margin-top: 28px;
	margin-bottom: 20px;
	overflow: hidden;
}

.containerWithRate {
	width: 100%;
	display: block !important;
}

.header {
	color: #fff;
	font-size: 20px;
	font-style: normal;
	font-weight: 900;
	line-height: 28px; /* 140% */
	letter-spacing: 0.374px;
	margin: 0;
	padding: 0;
	margin-left: 20px;
}

.headerRow {
	display: flex;
	justify-content: space-between;
}

.showAll:global(.MuiButton-root) {
	color: #9c85f6;

	text-align: right;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px; /* 142.857% */
	letter-spacing: 0.374px;
	text-decoration-line: underline;
	margin-right: 20px;

	text-transform: none;
}

@media screen and (min-width: 960px) {
	.grid {
		grid-template-columns: repeat(auto-fill, 160px);
		gap: 20px;
	}

	.gridWithRate {
		grid-template-columns: repeat(auto-fill, 190px);
	}

	.gridPreview {
		max-height: 500px;
		overflow: hidden;
	}

	.header {
		color: #fff;
		font-family: Inter;
		font-size: 40px;
		font-style: normal;
		font-weight: 900;
		line-height: 48px; /* 120% */
		letter-spacing: 0.374px;
		margin-left: 0;
	}

	.containerWithRate .header {
		font-size: 24px;
		line-height: 32px;
	}

	.container {
		display: table;
	}

	.showAll:global(.MuiButton-root) {
		margin-right: 0px;
	}
}
