.paywall-modal__inner {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 480px;
	height: fit-content;
	padding: 40px;
	box-sizing: border-box;

	border-radius: 28px;

	/* background: linear-gradient(180deg, rgba(33, 32, 52, 0.00) 17.08%, rgba(33, 32, 52, 0.80) 28.61%, #212034 39.72%); */
	background-color: #212034;
	color: white;
	align-items: center;
	overflow: hidden;

	display: flex;
	flex-direction: column;
	filter: drop-shadow(0px 12px 72px rgba(127, 95, 255, 0.4));

	background-attachment: local;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	background-position-y: 0;
}

.paywall-modal__bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	object-fit: cover;
	mask-image: linear-gradient(to bottom,
			rgba(0, 0, 0, 1) 40%,
			rgba(0, 0, 0, 0));
	-webkit-mask-image: linear-gradient(to bottom,
			rgba(0, 0, 0, 1) 40%,
			rgba(0, 0, 0, 0));
}

@media screen and (max-width: 550px) {
	.paywall-modal__bg {
		mask-image: linear-gradient(to bottom,
				rgba(0, 0, 0, 1) 40%,
				rgba(0, 0, 0, 0));
		-webkit-mask-image: linear-gradient(to bottom,
				rgba(0, 0, 0, 1) 40%,
				rgba(0, 0, 0, 0));
	}
}

.paywall-modal__inner:focus-visible {
	outline: none;
}

.paywall-modal__header {
	margin-top: 140px;
	z-index: 10;
}

.paywall-modal__header-h2 {
	color: var(--Light-System-White, #fff);
	text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.45);
	font-family: Inter;
	font-size: 42px;
	font-style: normal;
	font-weight: 900;
	line-height: 48px;
	/* 114.286% */
	margin: 0;
	padding: 0;
}

.paywall-modal__header-p {
	margin: 0;
	padding: 0;
	color: #faff02;
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
	/* 133.333% */
	letter-spacing: 0.6px;
	margin-top: 4px;
}

.paywall-modal__inner .modal__close {
	width: 24px;
	height: 24px;
	top: 16px;
	right: 16px;
}

.paywall-modal__inner .inbutton {
	font-family: Inter !important;
}

.paywall-modal__price-title {
	color: var(--Light-System-White, #fff);
	text-align: start;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 900;
	line-height: 24px;
	/* 150% */
	letter-spacing: 0.374px;
	margin: 0;
	margin-bottom: 15px;
}

.paywall-modal__price {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	padding: 20px 24px;
	box-sizing: border-box;
	border-radius: 20px;
	background: #191827;
	backdrop-filter: blur(12px);
	cursor: pointer;
	width: 100%;
	position: relative;
}

.paywall-modal__price:not(:last-child) {
	margin-right: 12px;
}

.paywall-modal__price_selected {
	border-radius: 20px;
	border: 2px solid #9c85f6;
	backdrop-filter: blur(12px);
}

.paywall-modal__price-monthly {
	color: var(--Light-Secondary-Color, #faff02);
	text-align: start;
	font-family: Inter;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	/* 120% */
	letter-spacing: 0.374px;
	margin: 0;
	margin-bottom: 9px;
}

.paywall-modal__price-yearly {
	color: #fff;
	text-align: start;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	/* 142.857% */
	letter-spacing: 0.1px;
	opacity: 0.3;
	margin: 0;
}

.paywall-modal__header-row {
	display: flex;
	align-items: center;
	width: 100%;
}

.paywall-modal__header_avatar {
	stroke-width: 2px;
	stroke: #fff;
	filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
	margin-right: 30px;
	width: 128px;
	height: 128px;
	flex-shrink: 0;
}

.paywall-modal__header {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.paywall-modal__header_text {
	color: var(--Light-System-White, #fff);
	font-family: Inter;
	font-size: 27px;
	font-style: normal;
	font-weight: 400;
	line-height: 30px;
	/* 111.111% */
	letter-spacing: 0.6px;
	margin: 0;
}

.paywall-modal__header_main {
	color: var(--Light-Secondary-Color, #faff02);
	font-family: Inter;
	font-size: 34px;
	font-style: normal;
	font-weight: 800;
	line-height: 36px;
	letter-spacing: 0.6px;
	margin: 0;
	width: 160px;
}

.paywall-modal__list {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 0;
	margin-top: 24px;
	list-style: none;
	width: 100%;
	align-items: flex-start;
	padding: 0;
	gap: 16px;
}

.paywall-modal__list-row {
	display: flex;
	align-items: center;
	margin-bottom: 16px;
	width: 100%;
	margin: 0;
}

.paywall-modal__list-row:last-child {
	margin-bottom: 0;
}

.paywall-modal__list-icon {
	color: var(--Secondary-Color, #faff02);
	font-family: 'SF Pro Display';
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: -0.1px;
	margin-right: 12px;
	width: 18px;
	height: 18px;
}

.paywall-modal__list-text {
	color: #fff;
	font-family: Inter;
	font-size: 17px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
	/* 150% */
	letter-spacing: 0.374px;
	margin: 0;
}

.paywall-modal__prices {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-top: 32px;
	height: 160px;
}

.paywall-modal__price_prices {
	display: flex;
	flex-direction: column;
}

.paywall-modal__btn.inbutton {
	margin-top: 32px;
	width: 100%;
	height: 48px !important;
	padding: 12px !important;
}

.paywall-modal__btn-loading.inbutton {
	animation: shimmer 3s infinite;
	background-image: linear-gradient(45deg, #43c4f1 0%, #7b5df3 72.92%);
	background-size: 200% 200%;
}

.paywall-modal__badge {
	align-items: center;
	border-radius: 30px;
	background: #faff02;
	display: inline-flex;
	gap: 10px;
	justify-content: center;
	padding: 4px 12px;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top: -11px;
}

.paywall-modal__text-wrapper {
	color: black;
	font-family: 'Inter-Bold', Helvetica;
	font-size: 12px;
	font-weight: 700;
	letter-spacing: 0;
	line-height: 12px;
	margin-top: -1px;
	text-align: center;
	white-space: nowrap;
	width: fit-content;
}

@keyframes shimmer {
	0% {
		background-position: 0% 50%;
	}

	50% {
		background-position: 100% 50%;
	}

	100% {
		background-position: 0% 50%;
	}
}

.paywall-modal__terms-row {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 28px;
}

.paywall-modal__terms-text {
	color: #4b496d;
	text-align: right;
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: 0.6px;
	text-decoration-line: underline;
	margin: 0;
	text-wrap: nowrap;
}

.paywall-modal__terms-delimiter {
	color: var(--Light-Text-Placeholder, #8a88ac);
	text-align: right;
	font-family: Inter;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: 0.6px;
	margin: 0 16px;
}

.paywall-modal__list-row_opacity {
	opacity: 0.3;
}

.paywall-modal__list-ultra {
	margin-left: auto;
	height: 20px;
}

.paywall-modal__list-text-img {
	font-size: 20px;
	margin-right: 12px;
	/* 120% */
}

@media screen and (max-width: 550px) {
	.paywall-modal__inner {
		width: calc(100% - 2 * 24px);
		height: auto;
		max-height: calc(100% - 2 * 18px);
		margin: 0;
		padding: 20px;
		overflow-y: auto;
		background-position-x: center;
	}

	.paywall-modal__header {
		margin-top: 120px;
	}

	.paywall-modal__price-title {
		margin-bottom: 4px;
		font-size: 14px;
		font-style: normal;
		font-weight: 900;
		line-height: 20px;
		/* 142.857% */
		letter-spacing: 0.374px;
	}

	.paywall-modal__header_avatar {
		height: 80px;
		width: 80px;
		margin-right: 20px;
	}

	.paywall-modal__header_text {
		font-size: 27px;
		line-height: normal;
		letter-spacing: 0.6px;
	}

	.paywall-modal__header_main {
		font-size: 27px;
		line-height: normal;
		letter-spacing: 0.6px;
	}

	.paywall-modal__list {
		margin-top: 14px;
		gap: 12px;
	}

	.paywall-modal__prices {
		margin-top: 14px;
		height: fit-content;
	}

	.paywall-modal__btn.inbutton {
		margin-top: 20px;
	}

	.paywall-modal__terms-row {
		margin-top: 20px;
	}

	.paywall-modal__price-monthly {
		font-size: 18px;
		font-style: normal;
		font-weight: 700;
		margin-bottom: 4px;
		line-height: 24px;
	}

	.paywall-modal__price-yearly {
		font-size: 10px;
		font-style: normal;
		font-weight: 400;
		line-height: 14px;
		/* 140% */
		letter-spacing: 0.1px;
	}

	.paywall-modal__price {
		padding: 12px;
		gap: 8px;
		height: fit-content;
	}
}

.paywall-modal__stars-container {
	display: flex;
	align-items: center;
	gap: 10px;
	width: calc(100% - 2 * 16px);
	justify-content: flex-start;
	margin-top: 24px;

	border-radius: 16px;
	border: 1px solid rgba(255, 255, 255, 0.08);
	background: rgba(255, 255, 255, 0.04);
	padding: 16px;
}

.paywall-modal__store-img {
	width: 32px;
	height: 32px;
}

.paywall-modal__stars-container-stars {
	display: flex;
	flex-direction: row;
	align-items: baseline;
	justify-content: center;
	gap: 10px;
	margin-right: auto;
}

.paywall-modal__stars-container-stars-title {
	font-size: 15px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
	/* 133.333% */
	letter-spacing: -0.3px;
}

.paywall-modal__stars-container-stars-reviews {
	color: #FFF;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
	/* 133.333% */
	letter-spacing: -0.3px;
	opacity: 0.4;
}

@media screen and (max-width: 550px) {
	.paywall-modal__stars-container-stars {
		flex-direction: column;
		align-items: flex-start;
		gap: 0;
	}
}

.paywall-modal__renewal-text {
	color: #FFF;
	text-align: center;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	/* 138.462% */
	letter-spacing: -0.3px;
	margin-top: 16px;
}