.dialog :global(.MuiPaper-root) {
	background: linear-gradient(0deg, #191827 0%, #191827 100%), #f1eeff;
	display: flex;
	flex-direction: column;
	font-family: 'Inter';
	align-items: center;
	color: black;
	text-align: center;
}

.dialog :global(.inbutton) {
	font-family: Inter;
	margin-top: 0 !important;
	margin: 0;
}

.header {
	width: 100%;
	display: flex;
	background: linear-gradient(0deg, #292841 0%, #292841 100%),
		rgba(255, 255, 255, 0.9);
	height: 64px;
	padding: 8px 16px;
	box-sizing: border-box;
	align-items: center;
}

.inputContainer {
	margin-left: 16px;
	width: 100%;
	border-radius: 16px;
	background: rgba(0, 0, 0, 0.5);

	backdrop-filter: blur(22px);
	height: 100%;
	padding: 12px 16px;
	box-sizing: border-box;
	align-items: center;
	justify-content: center;
	display: flex;
}

.clear {
	width: 19px;
	height: 19px;
	cursor: pointer;
}

.back {
	width: 24px;
	height: 24px;
	cursor: pointer;
}

.input {
	color: white;
	width: calc(100% - 20px);
	border: none;
	outline: none;
	background: transparent;
	font-family: 'Inter';
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.02em;
}

.resultContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 30px 20px 40px 20px;
	box-sizing: border-box;
	max-width: 992px;
	--w: 64px;
}

.resultRow {
	display: flex;
	width: 100%;
	cursor: pointer;
	align-items: center;
}

@media (hover: hover) {
	.resultRow:hover {
		background: rgba(156, 133, 246, 0.06);
	}
}

.resultRow {
	margin-bottom: 16px;
}

.resultAvatar {
	width: var(--w);
	height: var(--w);
	border-radius: 12px;
	background: #c4c4c4;
	margin-right: 16px;
}

.resultText {
	margin-left: 16px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.resultName {
	color: #fff;
	text-align: center;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
	/* 150% */
	letter-spacing: 0.2px;
	margin: 0;
	padding: 0;
}

.resultBio {
	margin: 4px 0;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	overflow: hidden;
	color: #cac8f2;
	text-align: left;
	text-overflow: ellipsis;
	font-family: Inter;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	/* 138.462% */
}

.qty {
	margin-top: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.qty :global(.messages-qty__text) {
	color: var(--text-placeholder, #8a88ac);
	font-family: Inter;
	font-size: 10px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin: 0;
}

.qty :global(.messages-qty__bubble) {
	width: 10px;
	height: 10px;
	margin-right: 4px;
}

.empty {
	color: #fff;

	text-align: center;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
	/* 150% */
	letter-spacing: 0.2px;
	margin: 0;
}

.empty:first-of-type {
	margin-top: 36px;
}

.empty:last-of-type {
	margin-bottom: 36px;
}

.letsCreate {
	font-family: Inter;
	margin: 0;
	padding: 0;
	margin-left: 0 !important;
}

.resultContainer :global(.create-btn.inbutton.MuiButton-root) {
	width: 100% !important;
}

.searchImg {
	margin-right: 12px;
}

.title {
	color: #fff;
	font-family: Inter;
	font-size: 40px;
	font-style: normal;
	font-weight: 900;
	line-height: 48px;
	/* 120% */
	letter-spacing: 0.374px;
	margin: 0 auto 32px 0;
	width: 100%;
	text-align: start;
}

@media screen and (min-width: 960px) {
	.resultContainer {
		--w: 72px;
	}

	.resultRow {
		display: flex;
		align-items: center;
		padding: 16px;
		margin-left: -16px;
		border-radius: 20px;
		margin-bottom: 0;
	}

	.title {
		font-size: 20px;
		line-height: 20px;
	}

	.qty :global(.messages-qty__text) {
		font-size: 13px;
	}

	.qty :global(.messages-qty__bubble) {
		width: 13px;
		height: 13px;
	}

	.qty {
		margin-top: 0px;
	}

	.resultBio {
		margin: 8px 0;
	}
}
