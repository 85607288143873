.container {
	display: flex;
	width: 100%;
	background: linear-gradient(0deg, #191827 0%, #191827 100%), #f1eeff;
	height: 100%;
}

.chat {
	width: 100%;
	display: flex;
	flex-direction: column;
	max-width: var(--max-chat-width);
	margin: 0 auto;
	/* 74px - header height + 40px bottom padding */
	height: calc(100dvh - 74px - 20px);
	padding: 0 10px;
}

.input {
	border-radius: 16px;
}

.header {
	padding: 10px 24px !important;
	box-sizing: border-box;
}

.logoContainer {
	width: var(--sidebar-width);
}

.headerContainer {
	display: flex;
	width: 100%;
	max-width: var(--max-chat-width);
	margin: 0 auto;
	align-items: center;
}

.avatar {
	width: 44px;
	height: 44px;
	border-radius: 12px;
	margin-left: 16px;
}

.chat__message_bot-image {
	border-radius: 20px;
}

:global(.chat__message_photo) {
	height: 300px;
	width: 200px;
	padding: 0 !important;
}

:global(.chat__message_video) {
	width: 200px;
	height: 200px;
	padding: 0 !important;
}

@media screen and (min-width: 768px) {
	:global(.chat__message_bot-image),
	:global(.chat__message_bot-video) {
		width: 100%;
		height: 100%;
	}

	:global(.chat__message_photo) {
		height: 400px;
		width: 266px;
	}

	:global(.chat__message_video) {
		height: 300px;
		width: 300px;
	}
}

.limited {
	background-color: #2b1624 !important;
}

.limited :global(.input) {
	background-color: #2b1624 !important;
}

.limited :global(.input)::placeholder {
	font-weight: 800 !important;
}

.limitInfo {
	display: flex;
}

.limitInfoYellow {
	margin: 0;
	color: #faff02;
	display: flex;
}

.limitInfoYellowBold {
	margin: 0;
	color: #faff02;
	font-weight: 800;
	margin: 0 8px;
}

.limitInfoRegular {
	margin: 0;
	font-weight: 400;
	color: white;
	margin-right: 8px;
}

.continueLimitBtn:global(.inbutton):global(.MuiButtonBase-root) {
	background-color: #5a34f0;
	width: 192px;
	flex-shrink: 0;
	box-sizing: border-box;
}
