.modal {
	display: flex;
	align-items: center;
	justify-content: center;
}

.modalContent {
	background-color: black;
	border-radius: 8px;
	position: relative;
}

.image {
	max-width: 95dvw;
	max-height: 95dvh;
}

@media screen and (max-width: 960px) {
	.modal {
		margin: 10px;
	}
}
