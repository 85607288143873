.tabs {
	display: flex;
	gap: 10px;
	margin-top: 32px;
}

.tab {
	display: flex;
	padding: 10px 24px;
	align-items: center;
	gap: 10px;
	border-radius: 22px;
	background: #292841;
	color: #9c85f6;
	font-family: Inter;
	font-size: 15px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
	/* 160% */
	letter-spacing: 0.1px;
	cursor: pointer;
	border: none;
	margin-right: 14px;
	height: 44px;
	box-sizing: border-box;
	padding: 10px 24px;
	white-space: nowrap;
	text-decoration: none;
	width: fit-content;
}

.active {
	border-radius: 22px;
	border: 2px solid #faff02;
	background: #212034;
	color: #faff02;
}

.botsContainer :global(.tiles-row_mobile) {
	padding: 0;
}

.botsContainer :global(.tiles-row-container) {
	width: 100%;
}
