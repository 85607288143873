.modal {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	border-radius: 30px;
	background-color: white;

	align-items: flex-start;
	overflow: hidden;
	background: linear-gradient(0deg, #212034 0%, #212034 100%), #f1eeff;
	font-family: Inter;
	padding: 32px;
	box-sizing: border-box;
	width: calc(100% - 64px);
	max-width: 800px;
	height: fit-content;
}

@media screen and (max-width: 600px) {
	.modal {
		width: calc(100% - 32px);
		max-height: 90dvh;
	}
}

.container {
	position: relative;
}

.close {
	position: absolute;
	top: 20px;
	right: 20px;
	cursor: pointer;
}

.title {
	color: #fff;

	font-family: Inter;
	font-size: 28px;
	font-style: normal;
	font-weight: 900;
	line-height: 36px; /* 128.571% */
	letter-spacing: 0.374px;
	margin: 0;
	padding: 0;
	margin-bottom: 32px;
	text-align: center;
}

.modal :global(.MuiInputBase-root) {
	width: 100%;
	background: linear-gradient(0deg, #191827 0%, #191827 100%), #f1eeff;
	color: black;
}

:global(.MuiSelect-select) {
	color: white !important;
}

:global(.MuiList-root) {
	background: linear-gradient(0deg, #191827 0%, #191827 100%), #f1eeff;
}

:global(.MuiSvgIcon-root) {
	fill: white !important;
}

.container .submit:global(.MuiButton-root) {
	font-family: Inter;
	width: 100%;
	margin-top: 32px;
}
