.spinner {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 20%;
}

.spinner-layout .spinner {
	justify-content: flex-start;
}

.spinner-layout {
	background-color: rgb(1, 1, 1, 0.2);
	width: 100%;
	z-index: 3;

	position: fixed;

	right: 0;
	bottom: 0;
	top: 0;
	left: 0;
	height: 100dvh;
}

.spinner.spinner_no-margin {
	margin-top: 0;
}
