.deleteButton {
	color: #ff4d4d !important;
}

.dialog :global(.MuiDialog-paper) {
	background-color: #292841;
	color: #ffffff;
	border-radius: 24px;
	margin: auto;
}

.dialog :global(.MuiDialogTitle-root) {
	color: #ffffff;
}

.dialog :global(.MuiTypography-root) {
	color: #ffffff;
}

.dialogButton:global(.MuiButton-root) {
	color: #ffffff;
	border-radius: 34px;
	background: #7b5df3;
	color: var(--Light-System-White, #fff);
	text-align: center;
	font-family: Inter;
	font-size: 18px;
	font-style: normal;
	font-weight: 900;
	line-height: 24px;
	/* 133.333% */
	letter-spacing: 0.374px;
	width: 100% !important;
	margin-top: 24px;

	text-transform: none;
	padding: 16px;
}

.content {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.title {
	color: var(--Dark-Text-Normal, #e2dfef);
	text-align: center;
	font-family: Inter;
	font-size: 24px;
	font-style: normal;
	font-weight: 800;
	line-height: 32px;
	/* 133.333% */
	letter-spacing: 0.374px;

	margin: 0;
	padding: 0;
}

.subtitle {
	color: #8b8aab;
	text-align: center;
	font-family: Inter;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	/* 146.667% */
	letter-spacing: 0.2px;

	margin: 0;
	padding: 0;
}

.messagesContainer {
	display: block;
	padding: 10px;
	align-self: stretch;
	box-sizing: border-box;
	width: 100%;
	height: fit-content;
	max-height: 50dvh;
	border-radius: 20px;
	background: rgba(0, 0, 0, 0.3);
	overflow-y: auto;
	overflow-x: hidden;
}

.messagesContainer::-webkit-scrollbar {
	width: 8px;
	height: 4px;
}

.messagesContainer::-webkit-scrollbar-track {
	border-radius: 20px;
}

.messagesContainer::-webkit-scrollbar-thumb {
	background-color: #191827;
	border: 1px solid #656469;
	border-radius: 20px;
}

.botMessages {
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 100%;
}

.messageRow {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	margin-top: 10px;
}

.botMessage {
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 100%;
	padding: 16px;
	box-sizing: border-box;

	border-radius: 14px 14px 14px 4px;
	background: rgba(34, 33, 54, 0.8);
	backdrop-filter: blur(17px);

	margin-right: 14px;
}

.check {
	width: 24px;
	height: 24px;
}

.messageIndex {
	color: var(--Dark-Text-Placeholder, #8b8aab);
	font-size: 12px;
	font-style: normal;
	font-weight: 700;
	line-height: 14px;
	letter-spacing: 0.2px;
}

.messageText {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 5;
	line-clamp: 5;
	align-self: stretch;

	overflow: hidden;
	color: var(--Dark-Text-Normal, #e2dfef);
	text-overflow: ellipsis;
	font-size: 17px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: 0.4px;
}

.messageTextExpanded {
	-webkit-line-clamp: unset;
	line-clamp: unset;
}

.messageExpand {
	display: flex;
	gap: 8px;

	cursor: pointer;
}

.messageExpandText {
	color: var(--Dark-Main-Color, #7b5df3);
	font-size: 12px;
	font-style: normal;
	font-weight: 700;
	line-height: 14px;
	letter-spacing: 0.2px;
}

.messageExpandArrow {
	rotate: 90deg;
}

.messageExpandArrowExpanded {
	rotate: 270deg;
}

.userMessage {
	display: flex;
	padding: 12px 16px;
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;
	flex: 1 0 0;
	border-radius: 14px 14px 4px 14px;
	background: rgba(90, 52, 240, 0.8);
	backdrop-filter: blur(17px);
	align-self: flex-end;
	max-width: 75%;
	margin-left: auto;
}

.userMessageText {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	line-clamp: 3;
	align-self: stretch;
	overflow: hidden;
	color: var(--Dark-Text-Normal, #e2dfef);
	text-overflow: ellipsis;
	font-size: 17px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: 0.4px;
}

.userMessageTextExpanded {
	-webkit-line-clamp: unset;
	line-clamp: unset;
}

.messageExpandTextWhite {
	color: #fff !important;
}
