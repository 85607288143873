.dialog :global(.MuiPaper-root) {
	background: linear-gradient(0deg, #191827 0%, #191827 100%), #f1eeff;
	display: flex;
	flex-direction: column;
	font-family: 'Inter';
	align-items: center;
	color: white;
	text-align: center;
	width: 100%;
}

.dialog :global(.inbutton) {
	font-family: Inter;
	margin: 0;
}

.container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	height: 100%;
}

.content {
	padding: 20px;
	box-sizing: border-box;
	width: 100%;
	height: calc(100dvh - 64px);
	display: grid;
	grid-template-rows: 1fr 100px;
}

.header {
	display: flex;
	width: 100%;
	justify-content: flex-start;
	position: relative;
	background-color: #292841;
	padding: 20px 12px;
	box-sizing: border-box;
	height: 64px;
}

.back {
	flex-shrink: 0;
}

.title {
	color: #fff;
	font-family: Inter;
	font-size: 18px;
	font-weight: 900;
	line-height: 24px;
	letter-spacing: 0.37400001287460327px;
	text-align: center;
	margin: 0;
}

.headerCol {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	margin-left: -20px;
}

.block {
	width: 100%;
	margin-bottom: 32px;
}

.textArea {
	border-radius: 20px;
	border: 0px solid var(--Dark-Main-Bg, #222134);
	background: linear-gradient(0deg, #212034 0%, #212034 100%), #191827;
	box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
	color: #e2dfef;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	/* 160% */
	letter-spacing: 0.1px;
	height: 100%;
	box-sizing: border-box;
	width: 100%;
}

.textArea::placeholder {
	color: var(--Dark-Text-Placeholder-Alt, #565470);
}

.btn {
	border-radius: 100px !important;
	background-color: var(--Dark-Main-Color, #7b5df3) !important;
	font-family: Inter;
	color: var(--Dark-System-White, #fff) !important;
	width: 100%;
	margin-top: 20px !important;
}

.btn:disabled {
	opacity: 0.2;
}

.box {
	height: 4px;
	width: 128px;
	margin-top: 8px;
}

.progress {
	background-color: #151521;
	border-radius: 20px;
	height: 4px;
	left: 0;
	position: relative;
	top: 0;
	width: 128px;
}

.rectangle {
	background: linear-gradient(
		270deg,
		rgb(90, 52, 240) 0%,
		rgb(80.59, 54.55, 240.51) 45.78%,
		rgb(193.16, 57, 241) 100%
	);
	border-radius: 20px;
	height: 4px;
}

.error {
	position: absolute;
	color: red;
	font-size: 14px;
	bottom: 88px;
}
