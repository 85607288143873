.avatarSelector {
	width: fit-content;
	margin: 0 auto;
	position: relative;
}

.avatar {
	width: 155px;
	height: 155px;
	border-radius: 20px;
	object-fit: contain;
}

.edit {
	position: absolute;
	right: -12px;
	bottom: -12px;
	cursor: pointer;
}

.fileInput {
	display: none;
}
