.textArea {
	background: #212034;
	padding: 20px;
	border-radius: 16px;
	box-sizing: border-box;
	width: 100%;
	height: 122px;
	font-family: Inter;
	border-radius: 16px;
	color: white;
	height: 180px;
}

.textArea::placeholder {
	color: #5a5976;
}

.error {
	border: 1px solid red;
}

.errorText {
	color: red;
	margin: 0;
	padding: 0;
	text-align: left;
}
.textArea:disabled {
	background-color: #1a1928;
	color: #3f3d56;
	cursor: not-allowed;
	border: 1px solid #3f3d56;
}

.textArea:disabled::placeholder {
	color: #3f3d56;
}
