.voice-selector-row {
	display: flex;
	padding: 18px 20px;
	align-items: center;
	width: 100%;

	border-radius: 16px;
	background: #fff;
	box-shadow: 0px 8px 20px 0px rgba(36, 22, 91, 0.05);
	box-sizing: border-box;

	font-size: 17px;
	font-family: Montserrat;
	font-weight: 500;
	line-height: 24px;

	position: relative;
}

.voice-selecotr-row__label {
	color: var(--light-purp-color);
}
.voice-selector-row__name {
	margin: 0;
	margin-left: 8px;
}

.voice-selector-row__arrow {
	margin-left: auto;
}
.voice-selector-row__arrow_revert {
	transform: rotate(180deg);
}

.voice-selector-row__container {
	position: absolute;
	padding: 16px;
	box-sizing: border-box;

	top: calc(100% + 8px);
	left: 0;
	width: 100%;
	z-index: 1;

	border-radius: 16px;
	background: #fff;
	box-shadow: 0px 8px 20px 0px rgba(36, 22, 91, 0.05);
	box-sizing: border-box;

	max-height: 420px;
	overflow-y: auto;
	overflow-x: hidden;
}

.voice-selector-row__container_top {
	top: auto;
	bottom: calc(100% + 8px);
	max-height: none;
}

.voice-selector-row__container .voice-row {
	padding: 12px;
	box-sizing: border-box;
}

.voice-type-switcher.double-switcher {
	width: 100%;
	margin-bottom: 10px;
}

.voice-type-switcher.double-switcher .option {
	width: 50%;
}

.voice-selector-row__icon {
	margin-right: 12px;
}

.voice-selector-row__name {
	color: #5a5976;
	margin-left: auto;
	margin-right: 12px;
}

.voice-selector-row__arrow_right {
	margin-left: 0;
}
