.edit-bot__descriptions__suggestions .suggestion {
	height: fit-content;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	border-width: 1px;
	margin-top: 10px;
}

.edit-bot__descriptions__suggestions .suggestion {
	margin-right: 10px;
}

.edit-bot__example {
	width: 100%;
	height: 100%;
	position: relative;
}
