.actionsRowLlm {
	margin-left: 8px;
	display: flex;

	gap: 8px;
	padding: 12px 16px;
	align-items: center;

	border-radius: 12px;
	background: rgba(0, 0, 0, 0.2);
	box-sizing: border-box;
	cursor: pointer;

	position: relative;
	z-index: 100;
}

.actionsRowLlmName {
	color: #fff;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
	margin: 0 auto 0 8px;
}

.llmSelectorImg {
	width: 28px;
	height: 28px;
}

.actionsRowLlmSelector {
	position: absolute;
	bottom: 40px;
	right: 0;
	min-width: 260px;
	box-sizing: border-box;
	display: inline-flex;
	flex-direction: column;
	align-items: flex-start;
	border-radius: 10px;
	background: #292841;
	box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.45);
}

.llmSelectorRow {
	display: flex;
	gap: 12px;
	padding: 12px 16px;
	box-sizing: border-box;
	width: 320px;
	cursor: pointer;
	align-items: center;
	background: #292841;
}

.llmSelectorRow:first-of-type {
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}

.llmSelectorRow:last-of-type {
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

.llmSelectorRow:not(:first-of-type) {
	border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.actionsRowLlmSelectorBottom {
	bottom: 0;
	top: 60px;
}

.llmSelectorLock,
.llmSelectorCheck {
	margin-left: auto;
}

.llmSelectorTextWrapper {
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.llmSelectorText {
	color: #fff;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px; /* 125% */
}

.llmSelectorDescription {
	color: #a4a2c6;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px; /* 138.462% */
}

.actionsRowLlmSelectorRight {
	right: auto;
	left: 0;
}

@media (max-width: 600px) {
	.actionsRowLlmName {
		display: none;
	}

	.actionsRowLlm {
		width: fit-content;
		padding: 12px;
		position: unset;
	}

	.actionsRowLlmArrow {
		display: none;
	}

	.llmSelectorRow {
		width: calc(100dvw - 32px);
	}

	.actionsRowLlmSelector {
		box-shadow: 0px 400px 400px 400px rgba(0, 0, 0, 0.45);
	}
}
