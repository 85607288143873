.container {
	width: calc(100% - var(--sidebar-width) + 2 * 12px);
	max-width: 1080px;
	margin: 0 0 0 auto;
	padding: 0 16px 0 40px;
	box-sizing: border-box;
}

.content {
	display: flex;
	align-items: center;
	gap: 20px;
}

.title {
	color: #fff;
	font-family: Inter;
	font-size: 27px;
	font-style: normal;
	font-weight: 900;
	line-height: 36px; /* 133.333% */
	letter-spacing: 0.374px;
	padding: 0;
	margin: 0;
}

.back {
	cursor: pointer;
}

@media (max-width: 961px) {
	:global(.header__logo):has(+ .container) {
		display: none;
	}

	.container {
		margin: 0px;
		width: 100%;
		padding: 0px;
	}

	.title {
		font-size: 18px;
	}
}
