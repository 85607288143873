.periodSwitcher {
	border-radius: 14px !important;
	background: #12121b !important;
	padding: 4px;
	box-sizing: border-box;
	width: 90%;

	z-index: 100;

	margin-top: 20px;
}

@media screen and (max-width: 760px) {
	.periodSwitcher {
		width: 100%;
	}
}

.btn:global(.MuiButtonBase-root) {
	padding: 8px 30px;
	color: #9c85f6;

	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 18px; /* 128.571% */
	letter-spacing: 0.1px;
	background: #12121b;
	border: none;

	text-transform: none;

	width: 50%;
}
.btn:global(.MuiButtonBase-root):hover {
	border: none;
}

.btnSelected:global(.MuiButtonBase-root) {
	border-radius: 10px !important;
	background: #292841 !important;
	color: #fff;
}
