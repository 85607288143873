.modal {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	border-radius: 30px;
	background-color: white;

	align-items: flex-start;
	min-width: 320px;
	overflow-y: scroll;
	overflow-x: hidden;
	background: linear-gradient(0deg, #212034 0%, #212034 100%), #f1eeff;
	font-family: Inter;
	height: 642px;
}

.container {
	position: relative;
}

.close {
	position: absolute;
	top: 20px;
	right: 20px;
	cursor: pointer;
}

.image {
	width: 100%;
	height: 50%;
	object-fit: cover;
	aspect-ratio: 1/1;
}

.content {
	padding: 24px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
}

.title {
	color: #fff;
	font-size: 28px;
	font-style: normal;
	font-weight: 900;
	line-height: 36px; /* 128.571% */
	letter-spacing: 0.374px;
	padding: 0;
	margin: 0;
}

.list {
	padding: 0;
}

.li {
	display: flex;
	margin-top: 12px;
}

.p {
	color: #fff;

	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px; /* 150% */
	letter-spacing: 0.374px;
	margin: 0;
	margin-left: 12px;
}

.button:global(.MuiButtonBase-root) {
	font-family: Inter;
	margin-top: 24px;
}

.storeBtns {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

@media screen and (min-width: 960px) {
	.modal {
		width: 800px;
		height: 432px;
		max-height: none;
	}

	.container {
		display: flex;
		flex-direction: row;
	}

	.image {
		max-height: none;
		height: 432px;
		object-fit: fill;
		width: 50%;
	}

	.content {
		padding: 32px 40px;
		box-sizing: border-box;
		width: 50%;
	}

	.list {
		margin-top: 20px;
	}

	.li:not(:first-child) {
		margin-top: 16px;
	}
	.storeBtns {
		justify-content: center;
		align-items: center;
	}

	.storeBtns :global(.store-btns) {
		flex-direction: column;
		margin-top: 40px;
	}

	.storeBtns :global(.store-btns) :global(.store-btn):not(:first-child) {
		margin-top: 20px;
	}

	.qr {
		width: 140px;
		height: 140px;
		margin-right: 30px;
		margin-top: 28px;
	}
}
