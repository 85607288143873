.dislike-feedback-modal {
	max-width: 720px;
	height: fit-content;
	margin: auto;
	border-radius: 20px;
	background: rgba(41, 40, 65, 1);
	box-sizing: border-box;
}

.dislike-feedback-modal__inner {
	display: flex;

	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 40px;
	gap: 20px;
}

.dislike-feedback-modal__title {
	color: #fff;
	font-size: 32px;
	font-style: normal;
	font-weight: 900;
	line-height: 48px;
	/* 150% */
	letter-spacing: 0.374px;
	margin: 0;
	padding: 0;
}

.dislike-feedback-modal__inner .dislike-feedback-modal__textarea {
	border-radius: 16px;
	border: 1px solid #292841;
	background: #212034;
	color: white;
}

.dislike-feedback-modal__inner .dislike-feedback-modal__textarea::placeholder {
	color: #5a5976;
}

.dislike-feedback-modal__button.inbutton.MuiButtonBase-root {
	width: 100% !important;
	border-radius: 34px;
	background: #5a34f0;
	color: white;
	font-family: Inter;
}

.dislike-feedback-modal__inner .dislike-feedback-modal__textarea_feedback {
	height: 160px;
	box-sizing: border-box;
	width: 100%;
}

.dislike-feedback-modal__textarea_error {
	color: #ff4d4f !important;
	border: 1px solid #ff4d4f !important;
}

@media screen and (max-width: 800px) {
	.dislike-feedback-modal {
		width: calc(100% - 2 * 20px);
		margin: auto;
	}

	.dislike-feedback-modal__inner {
		padding: 24px 20px;
		gap: 24px;
	}

	.dislike-feedback-modal__title {
		font-size: 24px;
		line-height: 32px;
		/* 133.333% */
		letter-spacing: 0.374px;
	}
}
