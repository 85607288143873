.modal__close {
	position: absolute;
	top: 24px;
	right: 24px;
	color: #ddd;
	width: 24px;
	height: 24px;
	cursor: pointer;
	z-index: 2;
}
