.content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.pageContainer {
	overflow-y: auto;
}

.pageContainer::-webkit-scrollbar {
	width: 0px;
	height: 0px;
}

.pageContainer::-webkit-scrollbar-track {
	border-radius: 20px;
}

.pageContainer::-webkit-scrollbar-thumb {
	background-color: #191827;
	border: 1px solid #191827;
	border-radius: 20px;
}

.botRow {
	display: flex;
	gap: 24px;
	align-items: center;
	justify-content: space-between;
	margin-top: 40px;
	width: 100%;
}

.avatar {
	width: 180px;
	height: 180px;
	border-radius: 50px;
	aspect-ratio: 1/1;
}

.tags {
	display: flex;
	gap: 8px;
}

.descriptionCol {
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-right: auto;
}

.tag {
	color: #e2dfef;
	text-align: center;
	font-family: Inter;
	font-size: 15px;
	font-style: normal;
	font-weight: 700;
	line-height: 18px;
	/* 120% */
	letter-spacing: 0.1px;
	display: flex;
	padding: 10px 12px 10px 16px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 30px;
	background: var(--Dark-Main-Bg, #222134);
	text-decoration: none;
	cursor: pointer;
}

.name {
	color: #fff;
	font-family: Inter;
	font-size: 27px;
	font-style: normal;
	font-weight: 900;
	line-height: 36px;
	/* 133.333% */
	letter-spacing: 0.374px;
	padding: 0;
	margin: 0;
}

.nameBlock {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.description {
	overflow: hidden;
	color: #cac8f2;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	align-self: stretch;
	padding: 0;
	margin: 0;
}

.username {
	color: #565470;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 125% */
	letter-spacing: 0.374px;
	text-decoration: none;
}

.botExtra {
	display: flex;
	padding: 0px 24px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 20px;
	align-self: stretch;

	border-radius: 24px;
	border: 1px solid #292841;
	background: #212034;
	height: 178px;
}

.botExtraRow {
	display: flex;
	gap: 20px;
	align-items: center;
	justify-content: center;
}

.likeBtn {
	margin-bottom: 0;
	display: flex;
	padding: 12px 16px;
	justify-content: center;
	align-items: center;
	gap: 6px;
	flex: 1 0 0;
	border-radius: 14px;
	border: 1px solid #5a5976;
	height: 48px;
	cursor: pointer;
}

.likeBtn img {
	width: 20px;
	height: 20px;
}

.likeBtn span {
	color: #8b8aab;
	text-align: center;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
	/* 150% */
	letter-spacing: 0.1px;
}

.shareBtn {
	display: flex;
	width: 48px;
	height: 48px;
	padding: 10px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 12px;
	border-radius: 14px;
	border: 1px solid #5a5976;
	box-sizing: border-box;
	cursor: pointer;
}

.startChat {
	width: 100%;
	border-radius: 40px !important;
}

.similarRow {
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-top: 40px;
	overflow: hidden;
	width: 100%;
}

.rowTitle {
	margin: 0;
	padding: 0;
	color: #8b8aab;
	font-family: Inter;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 28px;
	/* 140% */
	letter-spacing: 0.374px;
}

.similarBots {
	display: flex;
	gap: 16px;
	overflow-x: scroll;
	overflow-y: hidden;
}

.similarBots::-webkit-scrollbar {
	width: 0px;
	height: 0px;
}

.similarBots::-webkit-scrollbar-track {
	border-radius: 20px;
}

.similarBots::-webkit-scrollbar-thumb {
	background-color: #191827;
	border: 1px solid #191827;
	border-radius: 20px;
}

.similarBot {
	display: flex;
	padding: 10px;
	align-items: center;
	gap: 16px;
	border-radius: 20px;
	border: 1px solid #292841;
	background: rgba(255, 255, 255, 0.02);
	cursor: pointer;
	box-sizing: border-box;
	width: 228px;
	flex-shrink: 0;
	text-decoration: none;
}

.similarAvatar {
	width: 64px;
	height: 64px;
	border-radius: 14px;
}

.similarName {
	color: #9c85f6;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 22px;
	/* 137.5% */
	letter-spacing: 0.2px;
	max-width: 128px;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	margin: 0;
}

.photo {
	width: 180px;
	height: 180px;
	border-radius: 20px;
	object-fit: cover;
	justify-self: center;
	cursor: pointer;
}

.photos {
	display: grid;
	gap: 15px;
	grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
}

.scrollBtn::after {
	top: -22px;
	height: 88px;
}

@media screen and (max-width: 960px) {
	.mobilePage {
		background: #191827;
		height: auto !important;
	}

	.descriptionBlock {
		padding: 24px 20px 0px 20px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		width: 100%;
	}

	.row {
		display: flex;
		gap: 20px;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
	}

	.description {
		margin-top: 16px;
	}

	.tags {
		flex-wrap: wrap;
		justify-content: flex-start;
		margin-top: 16px;
	}

	.tag {
		display: flex;
		padding: 8px 16px;
		border-radius: 30px;
		background: var(--Dark-Main-Bg, #222134);
	}

	.botExtra {
		background: none;
		border: none;
		margin-top: 24px;
		display: flex;
		flex-direction: row;
		padding: 0;
		height: fit-content;
	}

	.startChat {
		border-radius: 14px !important;
	}

	.rowTitleMg {
		margin-left: 20px;
	}

	.similarBot:first-of-type {
		margin-left: 20px;
	}

	.similarBot:last-of-type {
		margin-right: 20px;
	}

	.similarRowPad {
		padding: 0 20px;
		box-sizing: border-box;
	}

	.photos {
		gap: 4px;
		grid-template-columns: repeat(auto-fill, minmax(104px, 1fr));
		padding-bottom: 40px;
	}

	.photo {
		width: 104px;
		height: 104px;
	}
}
