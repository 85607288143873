.chat__message {
	display: table;
	text-overflow: ellipsis;
	/* overflow: hidden; */
	min-height: 19px;
	max-width: 90%;
	text-align: start;
	border-radius: 14px;
	padding: 10px;
	margin-top: 20px;
	position: relative;
}

.chat__message_user {
	align-self: flex-end;
	background-color: #d9d6e6;
}

.chat__message_bot {
	align-self: flex-start;
	background-color: #f8f8fd;
}

.message__text {
	margin: 0;
	white-space: pre-wrap;
}

.message__text_italic {
	font-style: italic;
	color: #b7b7bb;
}

.chat__message_user .message__text_italic {
	color: #333333;
}

.chat__message-row {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 10px;
}

.chat__message-row .chat__message {
	margin-top: 0;
}

.message-row__photo {
	width: 30px;
	height: 30px;
	margin-right: 10px;
	border-radius: 8px;
}

.chat__message_bot_typing {
	width: 30px;
}

.dot-flashing {
	position: relative;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background-color: #9880ff;
	color: #9880ff;
	animation: dotFlashing 1s infinite linear alternate;
	animation-delay: 0.5s;
	margin-left: 10px;
	top: 5px;
}

.dot-flashing::before,
.dot-flashing::after {
	content: '';
	display: inline-block;
	position: absolute;
	top: 0;
}

.dot-flashing::before {
	left: -15px;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background-color: #9880ff;
	color: #9880ff;
	animation: dotFlashing 1s infinite alternate;
	animation-delay: 0s;
}

.dot-flashing::after {
	left: 15px;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background-color: #9880ff;
	color: #9880ff;
	animation: dotFlashing 1s infinite alternate;
	animation-delay: 1s;
}

.chat__message_bot-image {
	width: 200px;
	aspect-ratio: 2/3;
	border-radius: 12px;
	object-fit: cover;
}

.chat__message_bot-video {
	width: 200px;
	aspect-ratio: 1/1;
	border-radius: 12px;
}

.chat__message_bot-video_blured {
	object-fit: cover;
}

@keyframes dotFlashing {
	0% {
		background-color: #9880ff;
	}

	50%,
	100% {
		background-color: #ebe6ff;
	}
}

@media screen and (max-width: 630px) {
	.chat__message_user {
		background-color: var(--main-color);
		color: white;
		border-bottom-right-radius: 3px;
	}

	.chat__message_bot {
		border-bottom-left-radius: 3px;
	}
}

.actions-row {
	margin-top: 8px;
	display: flex;
	position: relative;
	width: calc(90% + 20px);
}

.actions-row_nomargin {
	margin-top: 0;
}

.actions-row__item_disabled {
	opacity: 0.5;
}

.actions-notify {
	position: absolute;
	bottom: 36px;
	left: 0;
	width: 315px;
	height: 125px;
	background-image: url('./images/notify-bg.svg');
	padding: 28px 20px;
	box-sizing: border-box;
}

.actions-row__dilike-selector {
	position: absolute;
	bottom: 40px;
	left: 0;
	width: 236px;
	box-sizing: border-box;
	display: inline-flex;
	flex-direction: column;
	align-items: flex-start;
	border-radius: 10px;
	background: #292841;
	box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.45);
}

.dilike-selector__row {
	display: flex;
	gap: 12px;
	padding: 12px 16px;
	box-sizing: border-box;
	width: 100%;
	cursor: pointer;
	align-items: center;
}

.dilike-selector__row:not(:first-of-type) {
	border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dilike-selector__row:last-of-type {
	border-top: 10px solid rgba(0, 0, 0, 0.15);
}

.dilike-selector__text {
	color: #9c85f6;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	/* 150% */
	margin: 0;
	padding: 0;
}

.dilike-selector__img {
	width: 24px;
	height: 24px;
}

.dilike-selector__row:hover .dilike-selector__text {
	color: white;
}

.actions-notify__close {
	position: absolute;
	top: 0;
	right: -10px;
	width: 30px;
	height: 30px;
	cursor: pointer;
}

.actions-notify__text {
	color: #fff;
	text-align: center;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	/* 150% */
}

.actions-notify__text span {
	color: #fff;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
}

.actions__layout::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100dvw;
	height: 100dvh;
	background-color: rgba(0, 0, 0, 0.5);
}

.actions-row_user {
	justify-content: flex-end;
}

.actions-row__item {
	border-radius: 10px;
	background: #292841;
	padding: 8px;
	box-sizing: border-box;
	width: 36px;
	height: 36px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	color: red;
}

.actions-row__item.actions-row__item_left {
	margin-left: auto !important;
}

.actions-row__item:has(+ .actions-row__item_left) {
	margin-right: 8px !important;
}

.actions-row__item_opacity {
	opacity: 0.5;
	cursor: crosshair;
}

.actions-row__item_with-text {
	width: auto;
	display: flex;
	gap: 8px;
	color: #7b5df3;
}

.actions-row__item:not(:first-of-type) {
	margin-left: 8px;
}

.actions-row_shrink {
	max-width: calc(400px * 2 / 3);
}

@media screen and (max-width: 768px) {
	.actions-row_shrink {
		max-width: calc(300px * 2 / 3);
	}
}

.chat__message_edit {
	width: 100%;
	height: 96px;
	border-radius: 16px 16px 16px 4px;
	border: 1px solid #34cef0;
	background: #292841 !important;
}

.chat__message_invalid {
	border: 1px solid red;
}

.edit-message {
	width: 100%;
	height: 100%;
	border: none;
	background: none;
	color: white;
	font-size: 16px;
	resize: none;
	color: #cac8f2;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	/* 150% */
}

.edit-message:focus {
	outline: none;
}

.edit-message__row {
	display: flex;
	margin-top: 12px;
}

.edit-message__row_user {
	justify-content: flex-end;
}

.edit-message__btn {
	width: 120px;
	height: 40px;
	flex-shrink: 0;
	border-radius: 34px;
	color: var(--Light-System-White, #fff);

	text-align: center;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 900;
	line-height: 24px;
	/* 150% */
	letter-spacing: 0.374px;
	text-align: center;
	cursor: pointer;
	border: none;
}

.edit-message__btn_cancel {
	background-color: #292841;
	margin-right: 8px;
}

.edit-message__btn_save {
	background: #7b5df3;
}

.edit-message__btn_save:disabled {
	opacity: 0.5;
}

.chat__message_loading {
	background-color: transparent !important;
}

.message__error {
	display: flex;
	gap: 8px;
}

.message__error .message__text {
	color: #fd5862;
}

.chat__message_delete-block {
	display: none;
}

.chat__message_delete-hover:hover .chat__message_delete-block {
	display: flex;
	position: absolute;
	top: -43px;
	right: 0;
	width: fit-content;
	box-sizing: border-box;
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	border-radius: 10px;
	background: #292841;
	box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.45);
}

.chat__message_delete-block_show {
	display: block;
	position: absolute;
	top: -50px;
	right: 0;
	width: fit-content;
	box-sizing: border-box;
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	border-radius: 10px;
	background: #292841;
	box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.45);
}

.chat__message_delete-block_left {
	left: 0;
	right: auto;
}

.chat__message_delete-block__row {
	display: flex;
	gap: 12px;
	padding: 12px 16px;
	box-sizing: border-box;
	width: 100%;
	cursor: pointer;
	align-items: center;
}

.chat__message_delete-block__text {
	color: #fd5862;
}

.chat__message_load-error {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 8px;
	height: 100%;
	padding: 12px;
	box-sizing: border-box;
}

@media screen and (min-width: 960px) {
	.chat__message_load-error {
		border-radius: 12px;
		border: 1px solid #777777;
	}
}

.chat__message_load-error img {
	width: 50px;
	height: 50px;
}

.chat__message_load-error__text {
	color: #777777;
	text-align: center;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
}

.chat__message_edited {
	padding-bottom: 20px;
	min-width: 80px;
}

.message__edited {
	position: absolute;
	bottom: 2px;
	right: 8px;
	font-size: 12px;
	color: #b7b7bb;
	font-style: italic;
}

.chat__message_user .message__edited {
	color: #777777;
}
