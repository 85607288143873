.store-btns {
	display: flex;
	flex-direction: row;
	margin-top: 20px;
}

.store-btn__image {
	width: 160px;
	height: 48px;
}

.store-btn:not(:last-of-type) {
	margin-right: 20px;
}

@media screen and (max-width: 630px) {
	.store-btn__image {
		width: 140px;
		height: 42px;
	}
}
