.randomTileTitle {
	color: #fff;
	text-align: center;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 900;
	line-height: 20px; /* 125% */
	width: 80px;
}

.randomTile {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	background-image: url(./images/bg.webp);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.randomTileImg {
	width: 100%;
}
