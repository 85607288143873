.double-switcher {
	align-items: center;
	background-color: #e8e8ef;
	border-radius: 22px;
	display: flex;
	justify-content: center;
	padding: 2px;
	width: fit-content;
}
.double-switcher .option {
	align-items: center;
	border-radius: 22px;
	display: flex;
	gap: 10px;
	justify-content: center;
	padding: 6px 16px;
	position: relative;
	width: fit-content;
	cursor: pointer;
}
.double-switcher .option_selected {
	background-color: #ffffff;
}
.double-switcher .text {
	color: #292841;
	font-family: 'Inter-Black', Helvetica;
	font-size: 13px;
	font-weight: 900;
	letter-spacing: 0.1px;
	line-height: 16px;
	margin-top: -1px;
	text-align: center;
	white-space: nowrap;
	width: fit-content;
}
.double-switcher .option_selected .text {
	color: #5a34f0;
}
