.category {
	padding: 32px 20px 0 20px;
	box-sizing: border-box;
}

.headerRow {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.header {
	color: #fff;
	font-size: 20px;
	font-style: normal;
	font-weight: 900;
	line-height: 28px; /* 140% */
	letter-spacing: 0.374px;
	margin: 0;
	padding: 0;
	margin-bottom: 16px;
}

.showAll:global(.MuiButton-root) {
	color: var(--Dark-Main-Color, #7b5df3);

	text-align: right;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px; /* 142.857% */
	letter-spacing: 0.374px;
	text-decoration-line: underline;

	text-transform: none;
}

.seeMore:global(.MuiButton-root) {
	overflow: hidden;
	color: var(--Dark-Main-Color, #7b5df3);
	text-align: center;
	text-overflow: ellipsis;

	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px; /* 125% */
	letter-spacing: 0.1px;
	border-radius: 16px;
	background: var(--Dark-Fourth-Bg, #282649);
	width: 100%;
	padding: 15px;
	text-transform: none;
}
