.btn:global(.inbutton) {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 40px;
	background: #5a34f0;
	color: var(--Light-Secondary-Color, #faff02) !important;
	padding: 6px 12px !important;
	box-sizing: border-box !important;
	height: 32px !important;
}

.text {
	color: var(--Light-Secondary-Color, #faff02);

	/* Titles/XSmall */
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
	/* 125% */
	letter-spacing: 0.1px;
	margin-left: 6px;
}
