.content {
	margin-top: 40px;
	max-width: 960px;
	margin-left: auto;
	margin-right: auto;
}

.profileContainer {
	display: flex;
	align-items: center;
	gap: 24px;
}

.avatarContainer {
	width: 180px;
	height: 180px;
	flex-shrink: 0;
}

.avatar {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 10px;
}

.infoContainer {
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 100%;
}

.name {
	color: #fff;
	font-family: Inter;
	font-size: 27px;
	font-style: normal;
	font-weight: 900;
	line-height: 36px; /* 133.333% */
	letter-spacing: 0.374px;
}

.username {
	color: #565470;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 125% */
	letter-spacing: 0.374px;
	text-decoration: none;
}

.description {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	line-clamp: 3;
	align-self: stretch;
	overflow: hidden;
	color: #cac8f2;
	text-overflow: ellipsis;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px; /* 150% */
}

.statContainer {
	margin-top: 40px;
	display: flex;
	width: 100%;
	padding: 24px 16px;
	box-sizing: border-box;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-radius: 20px;
	border: 1px solid rgba(141, 112, 255, 0.18);
	background: linear-gradient(
		271deg,
		rgba(141, 112, 255, 0.08) 40.5%,
		rgba(67, 196, 241, 0.08) 98.96%
	);
}

.statCol {
	width: 100%;
}

.statCol:not(:last-child) {
	border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.statTitle {
	color: var(--Dark-Text-Normal, #e2dfef);
	text-align: center;

	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 30px; /* 125% */
	letter-spacing: 0.374px;
}

.statDescription {
	align-self: stretch;
	color: var(--Dark-Text-Normal, #e2dfef);
	text-align: center;

	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px; /* 133.333% */
	letter-spacing: 0.374px;
}

@media (max-width: 960px) {
	.content {
		padding: 0px 20px;
	}

	.profileContainer {
		flex-direction: column;
	}

	.infoContainer {
		align-items: center;
	}

	.description {
		text-align: center;
	}

	.avatarContainer {
		width: 155px;
		height: 155px;
	}

	.name {
		font-size: 21px;
	}

	.description {
		font-size: 14px;
	}

	.statTitle {
		font-size: 20px;
	}

	.statDescription {
		font-size: 12px;
	}

	.statContainer {
		padding: 20px 16px;
		margin-top: 32px;
	}

	.description {
		display: block;
		align-self: stretch;
		white-space: normal;
		word-wrap: break-word;
	}
}
