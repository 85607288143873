.DESKTOP {
	background-color: #ffffff;
	height: 100vh;
	width: 100vw;
}

.DESKTOP .overlap {
	background-color: #191827;
	height: 100vh;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.DESKTOP .logoFull {
	height: 40px;
	left: 40px;
	position: absolute;
	top: 40px;
	width: 142px;
}

.DESKTOP .frame {
	align-items: center;
	display: inline-flex;
	flex-direction: column;
	gap: 40px;
}

.DESKTOP .IMG {
	height: 400px;
	position: relative;
	width: 400px;
}

.DESKTOP .TXT {
	height: 231px;
	margin-right: -4px;
	position: relative;
	width: 693px;
}

.DESKTOP .text-wrapper {
	color: #ffffff;
	font-family: 'Inter-Bold', Helvetica;
	font-size: 48px;
	font-weight: 700;
	left: 67px;
	letter-spacing: 0.37px;
	line-height: 60px;
	position: absolute;
	text-align: center;
	top: 0;
	white-space: nowrap;
}

.DESKTOP .div {
	color: #ffffff;
	font-family: 'Inter-Regular', Helvetica;
	font-size: 18px;
	font-weight: 400;
	left: 0;
	letter-spacing: 0.37px;
	line-height: 27px;
	position: absolute;
	text-align: center;
	top: 80px;
	white-space: nowrap;
}

.DESKTOP .button-fat {
	background-color: #7b5cf3;
	border-radius: 100px;
	height: 64px;
	left: 185px;
	position: absolute;
	top: 167px;
	width: 320px;
	cursor: pointer;
}

.DESKTOP .overlap-group {
	height: 24px;
	left: 102px;
	position: relative;
	top: 19px;
	width: 116px;
}

.DESKTOP .btn-next {
	height: 1px;
	left: 34px;
	position: absolute;
	top: 1px;
	width: 1px;
}

.DESKTOP .text-wrapper-2 {
	color: #ffffff;
	font-family: 'Inter-Black', Helvetica;
	font-size: 20px;
	font-weight: 900;
	height: 24px;
	left: 0;
	letter-spacing: 0.37px;
	line-height: 24px;
	position: absolute;
	text-align: center;
	top: 0;
	white-space: nowrap;
}

@media screen and (max-width: 740px) {
	.DESKTOP .overlap {
		justify-content: flex-start;
	}

	.DESKTOP .logoFull {
		position: unset;
		margin: 32px 0;
	}

	.DESKTOP .IMG {
		height: 200px;
		position: relative;
		width: 200px;
	}

	.DESKTOP .text-wrapper {
		color: #fff;
		text-align: center;
		font-family: Inter;
		font-size: 32px;
		font-style: normal;
		font-weight: 700;
		line-height: 36px; /* 112.5% */
		letter-spacing: 0.374px;
		position: unset;
		text-wrap: wrap;
	}

	.DESKTOP .TXT {
		position: relative;
		width: 253px;
	}

	.DESKTOP .div {
		position: unset;
		color: #fff;
		text-align: center;
		font-family: Inter;
		font-size: 15px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px; /* 160% */
		letter-spacing: 0.374px;
		text-wrap: wrap;
	}

	.DESKTOP .button-fat {
		position: unset;
	}

	.DESKTOP .button-fat {
		align-items: center;
		background-color: #7b5cf3;
		border-radius: 100px;
		display: inline-flex;
		gap: 10px;
		justify-content: center;
		padding: 16px 50px;
		position: relative;
		width: 100%;
		left: 0;
		top: 0;
		height: 56px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.DESKTOP .button-fat .text-wrapper {
		color: #ffffff;
		font-family: 'Inter-Black', Helvetica;
		font-size: 18px;
		font-weight: 900;
		letter-spacing: 0.37px;
		line-height: 24px;
		margin-top: -1px;
		position: relative;
		text-align: center;
		white-space: nowrap;
		width: fit-content;
	}

	.DESKTOP .overlap-group {
		height: 24px;
		left: 0;
		position: relative;
		top: 0;
		width: 116px;
	}
}
