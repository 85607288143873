.modal {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	border-radius: 30px;
	background-color: white;

	align-items: flex-start;
	overflow: auto;
	background: #191827;
	font-family: Inter;
	width: 800px;
	min-height: 780px;
}

.container {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 40px;
	box-sizing: border-box;
	font-family: Inter;
	height: 100%;
	overflow-y: auto;
}

.close {
	position: absolute;
	top: 20px;
	right: 20px;
	cursor: pointer;
}

.title {
	color: #fff;
	text-align: center;
	font-family: Inter;
	font-size: 32px;
	font-style: normal;
	font-weight: 900;
	line-height: 48px; /* 150% */
	letter-spacing: 0.374px;
	margin: 0;
	margin-bottom: 40px;
}

.buttonG:global(.inbutton) {
	font-family: Inter;
	margin-top: 40px !important;
	width: 100%;
}

.placeholder {
	width: 256px;
	height: 256px;
	border-radius: 100px;
}

.imageContainer {
	position: relative;
	display: inline-block;
	cursor: pointer;
}

.avatarSpinnerContainer {
	width: 256px;
	height: 258px;
	margin: auto;
}

.selected::after {
	content: '';
	position: absolute;
	top: -9px;
	left: -8px;
	right: -8px;
	bottom: -4px;
	border: 2px solid #9c85f6;
	border-radius: 112px;
	z-index: -1;
}

.check {
	position: absolute;
	z-index: 100;
	top: 0;
	right: 0;
}

.container :global(.MuiPaper-root) {
	background: linear-gradient(0deg, #191827 0%, #191827 100%), #f1eeff;
	display: flex;
	flex-direction: column;
	font-family: 'Inter';
	align-items: center;
	color: white;
	text-align: center;
	width: 100%;
}

.container :global(.inbutton) {
	font-family: Inter;
	margin-top: 0 !important;
	margin: 0;
}

.avatarContainer {
	display: flex;
	justify-content: space-around;
	width: 100%;
}

.btnsRow {
	display: flex;
	gap: 30px;
	width: 100%;
}

.btnsRow .buttonG {
	width: 100% !important;
	border-radius: 40px;
}

.buttonRefresh:global(.inbutton.MuiButton-root) {
	width: 100% !important;
	border-radius: 40px;
	background: #282649;
	border: none;
}

.refreshImg {
	margin-right: 10px;
}

.imageContainer {
	position: relative;
}

.refresh {
	position: absolute;
	bottom: -12px;
	right: -12px;
	cursor: pointer;
}
