.tile {
	--w: 96px;
	width: var(--w);
	height: 172px;
	padding: 0;
	border-radius: 12px;
	background: #212034;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	position: relative;
	text-decoration: none;
}

.rate {
	position: absolute;
	width: 100px;
	top: 145px;
}

.onlyPhotoTile {
	--w: 48px;
	height: 48px;
}

.onlyPhotoTile .image {
	border-radius: 12px;
}

.tile:last-of-type {
	margin-right: 20px;
}

.description {
	margin: 0;
	padding: 0 8px;
	margin-top: 4px;
	color: rgba(226, 223, 239, 0.7);
	text-align: center;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	box-sizing: border-box;
	max-width: 100%;
	text-decoration: none;
}

.mobileTile {
	width: 100%;
	border-radius: 16px;
	background: rgba(41, 40, 65, 0.4);
	border: 1px solid rgba(255, 255, 255, 0.08);
	display: flex;
	margin-bottom: 15px;
	position: relative;
}

.mobileImg {
	width: 100px;
	height: 100px;
	border-top-left-radius: 16px;
	border-bottom-left-radius: 16px;
	background: rgba(226, 223, 239, 0.5);
	aspect-ratio: 1/1;
}

.mobileTexts {
	display: flex;
	flex-direction: column;
	padding: 18px;
}

.mobileName {
	margin: 0;
	padding: 0;
	overflow: hidden;
	color: var(--Dark-Text-Normal, #e2dfef);
	text-overflow: ellipsis;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
	/* 150% */
	margin-bottom: 4px;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.mobileDescription {
	margin: 0;
	padding: 0;
	overflow: hidden;
	color: rgba(226, 223, 239, 0.5);
	text-overflow: ellipsis;
	text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
	font-family: Inter;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	/* 120% */
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

@media (hover: hover) {
	.tile:hover {
		background: linear-gradient(0deg, rgb(34, 28, 57), rgb(42, 35, 73)), #212034;
		outline: none;
		box-shadow: 0px 0px 24px rgba(90, 52, 240, 0.2);
	}
}

.tile:not(:last-of-type) {
	margin-right: 12px;
}

.avatar {
	border-radius: 12px 12px 0px 0px;
	width: 100%;
	height: var(--w);
}

.image {
	width: var(--w);
	height: var(--w);
	border-radius: 12px 12px 0px 0px;
	min-width: unset;
}

.name {
	color: #fff;
	text-align: center;
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 900;
	line-height: 16px;
	/* 133.333% */
	margin-top: 12px;

	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	text-wrap: nowrap;
	max-width: calc(var(--w) - 16px);
}

.qty {
	display: flex;
	align-items: center;
	justify-content: center;

	padding: 4px 8px;
	box-sizing: border-box;
	position: absolute;
	right: 8px;
	top: 8px;
	border-radius: 20px;
	background: rgba(0, 0, 0, 0.3);
	backdrop-filter: blur(20px);

	margin-top: 0;
}

.qty :global(.messages-qty__text) {
	color: rgba(255, 255, 255, 0.5);
	font-family: Inter;
	font-size: 10px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin: 0;
}

.qty :global(.messages-qty__bubble) {
	width: 10px;
	height: 10px;
	margin-right: 4px;
}

.tile.tileWithRate {
	--w: 190px;
	margin-right: 20px !important;
	height: 328px;
	width: var(--w);
	min-width: var(--w);
}

.tile.tileWithRate:last-of-type {
	margin-right: 0 !important;
}

.tile.tileWithRate .name {
	margin-top: 30px;
}

.tile.tileWithRate .description {
	-webkit-line-clamp: 2;
}

.tile.tileWithRate:first-of-type {
	margin-left: 20px !important;
}

@media screen and (min-width: 850px) {
	.tile {
		--w: 180px;
		height: 252px;
	}

	.name {
		font-size: 16px;
	}

	.qty :global(.messages-qty__text) {
		font-size: 13px;
	}

	.qty :global(.messages-qty__bubble) {
		width: 13px;
		height: 13px;
	}

	.onlyPhotoTile {
		--w: 98px;
		height: 98px;
	}
}

@media screen and (min-width: 960px) {
	.tile {
		--w: 160px;
		height: 250px;
		margin-right: 0 !important;
	}
	.tile.tileWithRate {
		margin-right: 20px !important;
	}
	.tile.tileWithRate:first-of-type {
		margin-left: 0px !important;
	}
}
