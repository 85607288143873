.fileInput {
	display: none;
}

.avatar {
	width: 200px;
	height: 200px;
	object-fit: contain;
}

.btnsRow {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 16px;
	width: 100%;
	margin-top: 32px;
}

.block {
	padding: 24px 20px;
	box-sizing: border-box;
	border-radius: 30px;
	border: 1px solid #222134;
	background: #292841;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.img {
	width: 36px;
	height: 36px;
	margin-bottom: 12px;
}

.blockText {
	color: #7b5df3;
	text-align: center;
	font-family: Inter;
	font-size: 15px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px; /* 133.333% */
	letter-spacing: 0.1px;
}

.avatarSelector :global(video) {
	width: 200px;
	height: 200px;
}

.doneBtn:global(.inbutton) {
	margin-top: 12px !important;
	width: 100%;
	border-radius: 100px !important;
}
