.login-modal {
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: calc(100% - 20px);
	margin: auto;
}

.login-modal__inner {
	background: linear-gradient(0deg, #212034 0%, #212034 100%), #f1eeff;
	color: white;
	padding: 2rem;
	border-radius: 8px;
	width: 100%;
	max-width: 400px;
	position: relative;
}

.login-modal__title {
	text-align: center;
	margin-bottom: 2rem;
}

.login-modal__buttons {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	margin-bottom: 1.5rem;
}

.provider-button {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.8rem;
	cursor: pointer;
	font-size: 1rem;
	gap: 0.5rem;
	border-radius: 16px;
	border: 2px solid #7b5df3;
	backdrop-filter: blur(22px);
	background: linear-gradient(0deg, #212034 0%, #212034 100%), #f1eeff;
	color: #fff;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 800;
	line-height: 20px; /* 125% */
}

.provider-button:hover {
	background: linear-gradient(0deg, #212034 0%, #212034 100%), #f1eeff;
}

.provider-button:disabled {
	opacity: 0.7;
	cursor: not-allowed;
}

.apple-button img {
	width: 20px;
	height: 20px;
}

.email-form {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	margin-bottom: 1rem;
}

.email-form input {
	padding: 0.8rem;
	border-radius: 16px;
	border: 2px solid #7b5df3;
	font-size: 1rem;
	background: linear-gradient(0deg, #212034 0%, #212034 100%), #f1eeff;
	color: #fff;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 125% */
}

.email-form button {
	padding: 0.8rem;
	background: #7b5df3;
	color: white;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	font-size: 1rem;
}

.email-form button:hover {
	background: #6846f1;
}

.email-form button:disabled {
	opacity: 0.7;
	cursor: not-allowed;
}

.auth-links {
	display: flex;
	justify-content: space-between;
	margin-bottom: 1rem;
}

.auth-links button {
	background: none;
	border: none;
	color: #7b5df3;
	cursor: pointer;
	padding: 0;
	font-size: 0.9rem;
}

.auth-links button:hover {
	text-decoration: underline;
}

.error-message {
	color: #dc3545;
	font-size: 0.9rem;
	margin-top: -0.5rem;
}

.login-modal__terms {
	text-align: center;
	font-size: 0.8rem;
	color: #5a5976;
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 166.667% */
}

.login-modal__terms a:hover {
	text-decoration: none;
}

.provider-button__icon {
	width: 20px;
	height: 20px;
}
