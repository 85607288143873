.content {
	margin-top: 40px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	max-width: 960px;
	margin-left: auto;
	margin-right: auto;
	box-sizing: border-box;
}

.chatPersonaRow {
	border-radius: 16px;
	border: 1px solid #292841;
	background: #212034;
	margin-top: 32px;
}

.createButton {
	width: 100%;
	height: 64px !important;
	border-radius: 100px !important;
	margin-top: 32px !important;
	background: #5a34f0 !important;
}

@media (max-width: 960px) {
	.content {
		padding: 0px 20px;
	}
	.createButton {
		margin-bottom: 40px !important;
	}
}
