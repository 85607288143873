.chat__send-button {
	border-radius: 10px;
	border-width: 0;
	width: 32px;
	height: 32px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.chat__send-button_active {
	background-color: var(--main-color);
}

.chat__send-button_disabled {
	background-color: #d9d6e6;
}
