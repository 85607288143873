.tile {
	display: flex;
	width: 100%;
	gap: 16px;
	border-radius: 16px;
	background: var(--Dark-Main-Bg, #222134);
	align-items: center;
	cursor: pointer;
}

.tile:not(:last-child) {
	margin-bottom: 12px;
}

.avatar {
	position: relative;
}

.image {
	width: 100px;
	height: 100px;
	flex-shrink: 0;
	border-radius: 16px 0px 0px 16px;
}

.texts {
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-grow: 1;
}

.rate {
	position: absolute;
	top: -15%;
	right: -10%;
	width: 50px;
	height: 50px;
}

.name {
	overflow: hidden;
	color: var(--Dark-Text-Normal, #e2dfef);
	text-overflow: ellipsis;

	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
	/* 133.333% */
	letter-spacing: 0.1px;
}

.description {
	overflow: hidden;
	color: var(--Dark-Text-Normal, #e2dfef);
	text-overflow: ellipsis;

	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	/* 128.571% */
	letter-spacing: 0.1px;

	display: -webkit-box;

	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
}

.likeBtn {
	height: 100%;
	flex-direction: column;
	padding: 12px 8px;
	box-sizing: border-box;
	border-radius: 10px;
	background: var(--Dark-Third-Bg, #2e2c46);
	width: 56px;
	flex-shrink: 0;
	cursor: pointer;
	margin: auto 16px;
}
