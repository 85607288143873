.container {
	position: relative;
	margin-right: 20px;
}

.btn {
	display: inline-flex;
	padding: 6px 12px;
	justify-content: flex-end;
	align-items: center;
	gap: 10px;
	border-radius: 8px;
	background: #191827;
	cursor: pointer;
	box-sizing: border-box;
	height: 40px;
	border-radius: 8px;
	background: #191827;
}

.btnDisabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.btnText {
	color: #9c85f6;
	font-family: Inter;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	/* 133.333% */
	letter-spacing: 0.4px;
	opacity: 0.5;
	text-wrap: nowrap;
	padding: 0;
	margin: 0;
}

.list {
	position: absolute;
	bottom: 60px;
	right: 0;
	background: #191827;
	border-radius: 8px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	overflow: hidden;
	display: none;
	border-radius: 10px;
	background: #292841;
	box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.45);
}

.listRow {
	padding: 12px 16px;
	display: flex;
	align-self: start;
	cursor: pointer;
	width: 172px;
	align-items: baseline;
}

.pairPhotoHint {
	margin-left: auto;
}

.listRow:hover {
	background: rgba(255, 255, 255, 0.02);
}

.listRow:hover .text {
	color: #fff;
}

.listVisible {
	display: inline-flex;
	flex-direction: column;
	align-items: flex-start;
}

.icon {
	color: #9c85f6;
	font-family: Inter;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	/* 120% */
	padding: 0;
	margin: 0;
	margin-right: 12px;
}

.text {
	color: #9c85f6;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	/* 150% */
	padding: 0;
	margin: 0;
}
