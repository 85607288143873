.container {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
	margin-top: 24px;
}

@media (max-width: 960px) {
	.container {
		padding: 0 20px;
		box-sizing: border-box;
		grid-template-columns: repeat(auto-fill, minmax(154px, 1fr));
	}
}
