.bot-tile {
	width: 180px;
	cursor: pointer;
	padding: 16px;
	background-color: white;
	border-radius: 14px;
}

.bot-tile__avatar {
	position: relative;
}

.bot-tile__image {
	aspect-ratio: 1/1;
	margin: 0;
	object-fit: cover;
	border-radius: 24px;
	background-color: #adacc5;
	width: 180px;
}

.bot-tile__messages-qty {
	margin-top: 8px;
	color: #adacc5;
}

.bot-tile__title {
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 28px;

	margin: 12px 0 0 0;
}

.bot-tile__author {
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;
	color: #8a88ac;

	margin: 4px 0 0 0;
}

.bot-tile__description {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	color: #292841;

	margin: 12px 0 0 0;
	text-align: justify;
}

@media (hover: hover) {
	.bot-tile:hover {
		background: linear-gradient(
				0deg,
				rgba(90, 52, 240, 0.09),
				rgba(90, 52, 240, 0.09)
			),
			#ffffff;
		outline: 1px solid #ffffff;
		box-shadow: 0px 0px 24px rgba(90, 52, 240, 0.2);
	}
}

@media screen and (max-width: 850px) {
	.bot-tile__image {
		min-width: 180px;
	}

	.bot-tile {
		width: 180px;
		padding: 16px;
	}
}

@media screen and (max-width: 730px) {
	.bot-tile__image {
		width: 150px;
		min-width: 150px;
	}

	.bot-tile {
		width: 150px;
		padding: 14px;
		margin-top: 0px;
	}
}

@media screen and (max-width: 500px) {
	.bot-tile__image {
		min-width: 128px;
		width: 128px;
	}

	.bot-tile {
		width: 128px;
		padding: 16px;
		margin-top: 0px;
	}

	.bot-tile__title {
		font-size: 16px;
		line-height: 24px;
	}
}

@media screen and (max-width: 350px) {
	.bot-tile__image {
		min-width: 90px;
		width: 90px;
	}

	.bot-tile {
		width: 90px;
	}
}
