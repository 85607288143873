.container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.row {
	display: flex;
	width: 100%;
	border-radius: 16px;
	border: 1px solid #292841;
	background: #212034;
	padding: 14px 20px;
	box-sizing: border-box;
	align-items: center;
	cursor: pointer;
}

.rowSmall {
	padding: 12px 16px;
}

.text {
	margin: 0;
	color: #fff;
	font-family: Inter;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: 0.4px;
	margin-left: 12px;
}

.checkbox {
	margin-left: auto;
	width: 28px;
	height: 28px;
	flex-shrink: 0;
	border-radius: 6px;
	border: 1px solid #292841;
	background: #191827;
	cursor: pointer;
	display: flex;
}

.switch {
	margin-left: auto;
	width: 28px;
	height: 28px;
	flex-shrink: 0;
	cursor: pointer;
	display: flex;
}

.check {
	margin: auto;
}

.lockSmall {
	width: 32px;
	height: 32px;
}

.note {
	color: #5a5976;

	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px; /* 128.571% */
	letter-spacing: 0.1px;
	margin: 0;
	margin-top: 12px;
	text-align: left;
	padding: 0 20px;
}
