.side {
	width: var(--sidebar-width);
	background: linear-gradient(0deg, #212034 0%, #212034 100%),
		rgba(0, 0, 0, 0.04);
	backdrop-filter: blur(22px);
	height: calc(100dvh - 64px);
	padding: 30px 20px 15px 20px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.sideTop {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: fit-content;
	gap: 16px;
}
.sideBottom {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: fit-content;
	padding-top: 16px;
}

.sideItem {
	color: white;
}

.botsList {
	display: flex;
	flex-direction: column;
	margin-top: 32px;
	overflow-y: auto;
	/* max-height: calc(100dvh - 255px); */
	position: relative;
	margin-bottom: auto;
}

.botListWithCreator {
	/* max-height: calc(100dvh - 385px); */
}

.botListWithPremium {
	/* max-height: calc(100dvh - 455px); */
}

.botsList::-webkit-scrollbar {
	width: 0px;
	height: 0px;
}

.botRow {
	display: flex;
	align-items: center;
	width: 100%;
	color: #a4a2c6;
	font-family: Inter;
	font-size: 15px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px; /* 133.333% */
	letter-spacing: 0.2px;
	cursor: pointer;
	border-radius: 10px;
	text-decoration: none;
}

.botRow:not(:first-child) {
	margin-top: 20px;
}

.botImg {
	width: 48px;
	height: 48px;
	border-radius: 10px;
}

.botName {
	margin-left: 16px;
}

.selected {
	background-color: #292841;
	color: white;
}

.create {
	width: 100% !important;
}

.featuredTitle {
	color: #fff;
	font-family: Inter;
	font-size: 27px;
	font-style: normal;
	font-weight: 900;
	line-height: 36px; /* 133.333% */
	letter-spacing: 0.374px;
	margin-bottom: 16px;
	margin-top: 20px;
}

.nav {
	display: flex;
	justify-content: space-between;
	margin-top: auto;
}

.exh {
	color: #7370ab;
	text-align: center;
	font-size: 13px;
	text-decoration: none;
	padding: 0;
	margin: 0;
	margin-top: 8px;
}

@media (hover: hover) {
	.botRow:hover {
		background: rgba(156, 133, 246, 0.06);
	}
}

.botsList::after {
	content: '';
	position: fixed;
	bottom: 70px;
	width: 100%;
	left: 0;
	height: 50px;
	background: linear-gradient(
		180deg,
		rgba(25, 24, 39, 0) 0%,
		rgba(25, 24, 39, 0.99) 84.17%
	);
	pointer-events: none;
	display: none;
	max-width: var(--sidebar-width);
}

.botListWithPremium::after {
	bottom: 140px;
}

.botsList:not(.nofade)::after {
	display: block;
}

.premiumBtn {
	margin-bottom: 10px !important;
	margin-top: auto !important;
}

.navNoMargin {
	margin-top: 0 !important;
}
