.banner {
	background-image: url(./images/bg.webp);
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 72px;
	padding: 16px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	gap: 16px;
	border-radius: 16px;
	cursor: pointer;
}

.lock {
	width: 48px;
	height: 48px;
}

.boldText {
	color: #fff;
	font-size: 18px;
	font-style: normal;
	font-weight: 800;
	line-height: 22px; /* 122.222% */
}

.text {
	color: #fff;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px; /* 128.571% */
	opacity: 0.7;
}

.texts {
	display: flex;
	flex-direction: column;
	gap: 4px;
}
