.bot-page-container {
	height: 100vh;
	display: flex;
	flex-direction: column;
	background-color: var(--bg-color);
}

.bot-page {
	/* min-height: 1000px; */
	background-color: var(--bg-color);
}

@media screen and (max-width: 500px) {
	.bot-page {
		height: auto;
		min-height: max-content;
	}

	.bot-page_height {
		min-height: 100vh;
	}
}

.bot-page-content {
	margin: 60px auto;
	width: calc(100% - 320px);
	display: grid;
	grid-template-columns: 1fr 330px;
	column-gap: 40px;
	max-width: 1300px;
}

@media screen and (max-width: 1100px) {
	.bot-page-content {
		margin: 40px 60px;
		width: calc(100% - 120px);
		grid-template-columns: 1fr 220px;
	}
}

@media screen and (max-width: 500px) {
	.bot-page-content {
		display: flex;
		flex-direction: column;
		margin: 40px 24px;
		width: calc(100% - 24 * 2px);
	}
}
