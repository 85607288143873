.modal {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	border-radius: 30px;
	background-color: white;

	align-items: flex-start;
	overflow: scroll;
	background: linear-gradient(0deg, #212034 0%, #212034 100%), #f1eeff;
	font-family: Inter;
	padding: 32px;
	box-sizing: border-box;
	width: calc(100% - 64px);
	max-width: 800px;
	max-height: 400px;
}

@media screen and (max-width: 600px) {
	.modal {
		width: calc(100% - 32px);
		max-height: 90dvh;
	}
}

.container {
	position: relative;
}

.close {
	position: absolute;
	top: 20px;
	right: 20px;
	cursor: pointer;
}

.title {
	color: #fff;

	font-family: Inter;
	font-size: 28px;
	font-style: normal;
	font-weight: 900;
	line-height: 36px; /* 128.571% */
	letter-spacing: 0.374px;
	margin: 0;
	padding: 0;
	margin-bottom: 32px;
	text-align: center;
}

.modal :global(.copy-text-area) {
	box-sizing: border-box;
	width: 100%;
	color: #fff;
	border-radius: 16px;
	border: 1px solid #292841;
	background: #191928;
	margin-bottom: 32px;

	text-align: center;
	font-family: Inter;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 32px; /* 160% */
	letter-spacing: 0.374px;
}

.buttons {
	width: 100%;
	display: flex;
	justify-content: center;
	margin-bottom: 24px;
}

.buttons .a:not(:first-of-type) {
	margin-left: 24px;
}

.footer {
	color: #5a5976;
	text-align: center;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px; /* 150% */
	letter-spacing: 0.374px;
}

.footer span {
	color: #5a5976;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
	letter-spacing: 0.374px;
}

.img {
	width: 48px;
	height: 48px;
}
