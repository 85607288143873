.btn:global(.inbutton):global(.MuiButton-root) {
	width: 100%;
	height: 56px;
	border-radius: 12px;
	background: linear-gradient(
		272deg,
		#43c4f1 -35.01%,
		#7b5df3 49.91%,
		#8d70ff 96.4%
	);

	backdrop-filter: blur(22px);
	justify-content: flex-start;
	padding: 18px;
}

.btn:global(.inbutton):global(.MuiButton-root) p {
	padding: 0;
	margin-left: 12px;
	font-size: 16px;

	color: #fff;
	font-family: Inter;

	font-style: normal;
	font-weight: 800;
	line-height: 20px;
}
