.btn {
	display: flex;
	align-items: center;
	gap: 16px;

	border-radius: 12px;
	background: #292841;
	backdrop-filter: blur(22px);
	text-decoration: none;
	padding: 16px 20px;
	text-wrap: nowrap;
}

.img {
	width: 24px;
	height: 24px;
}

.text {
	color: #fff;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 800;
	line-height: 20px; /* 125% */
}
