.voice-row {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	line-height: 32px;
}

.voice-row .MuiRadio-root.Mui-checked {
	color: var(--main-color);
}

.play-btn {
	cursor: pointer;
}

.voice-select {
	cursor: pointer;
	margin: 0 0 0 auto;
}

.voice-text {
	margin: 0 0 0 10px;
	font-weight: 700;
	font-size: 18px;
	line-height: 21px;
	color: var(--secondary-color);
}
