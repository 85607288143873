.button {
	border-radius: 16px;
	height: 34px;
	padding: 24px 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.button_empty {
	color: var(--main-color);
	border: 2px solid var(--main-color);
	background-color: inherit;
}

.button_filled {
	background-color: var(--main-color);
	color: white;
	border: 1px solid var(--main-color);
}

@media (hover: hover) {
	.button:hover {
		opacity: 0.6;
		transition-duration: 0.5s;
	}
}

.inbutton.MuiButton-root {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	font-size: 17px;
	line-height: 28px;
	border-radius: 12px;
	text-transform: none;
	height: 48px;
	box-shadow: none;
}

.inbutton.MuiButton-contained {
	color: white;
	background-color: var(--main-color);
	border-color: var(--secondary-color);
}

@media (hover: hover) {
	.inbutton.MuiButton-contained:hover {
		background-color: #4226ad;
		transition: 0.6s;
	}
}

.inbutton.MuiButton-contained:disabled {
	opacity: 0.4;
	color: white;
	background-color: #4226ad;
}

@media (hover: hover) {
	.inbutton.MuiButton-outlined:hover {
		background-color: var(--main-color);
		color: white;
		opacity: 0.6;
		border-width: 2px;
		border-color: var(--main-color);
		transition: 0.6s;
	}

	.inbutton.MuiButton-outlined:hover img {
		filter: brightness(0) invert(1);
	}
}

.inbutton.MuiButton-outlined {
	color: var(--main-color);
	background-color: inherit;
	border-color: var(--main-color);
	border-width: 2px;
}

.inbutton.MuiButton-outlined.inbutton_inverted {
	color: white;
	background-color: transparent;
	border-color: white;
}
@media (hover: hover) {
	.inbutton.MuiButton-outlined:hover.inbutton_inverted {
		background-color: white;
		color: var(--main-color);
		border-color: white;
	}
	.inbutton.MuiButton-outlined.light:hover {
		border-width: 1px;
	}
}

.inbutton.MuiButton-outlined.light {
	border-width: 1px;
}

@media screen and (max-width: 1390px) {
	.inbutton.MuiButton-root {
		line-height: 20px;
		padding: 6px 12px;
	}
}

@media screen and (max-width: 600px) {
	.inbutton.MuiButton-root {
		font-size: 18px;
		line-height: 24px;
	}
}

.inbutton_loading.inbutton {
	animation: shimmer 3s infinite;
	background-image: linear-gradient(45deg, #43c4f1 0%, #7b5df3 72.92%);
	background-size: 200% 200%;
}

@keyframes shimmer {
	0% {
		background-position: 0% 50%;
	}

	50% {
		background-position: 100% 50%;
	}

	100% {
		background-position: 0% 50%;
	}
}
