.container {
	position: relative;
	overflow: hidden;
	padding: 2px;
	box-sizing: border-box;
	width: 100%;
}

.name {
	height: 60px;
	box-sizing: border-box;
	width: 100%;

	border-radius: 16px;
	border: 1px solid #292841;

	background: #212034;
	font-family: Inter;
	overflow: hidden;
	color: #fff;
}

.name:focus,
.name:focus-visible {
	outline: none;
}

.name::placeholder {
	color: #5a5976;
	font-family: Inter;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: 0.4px;
}

.name:disabled {
	background-color: #1a1928;
	color: #3f3d56;
	cursor: not-allowed;
	border: 1px solid #3f3d56;
}

.name:disabled::placeholder {
	color: #3f3d56;
}

.pronoun {
	position: absolute !important;
	top: 12px;
	right: 16px;

	border-radius: 14px !important;
	background: #12121b;
	padding: 4px;
	box-sizing: border-box;
}

.btn:global(.MuiButtonBase-root) {
	padding: 8px 30px;
	color: #9c85f6;

	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 18px; /* 128.571% */
	letter-spacing: 0.1px;
	background: #12121b;
	border: none;
}
.btn:global(.MuiButtonBase-root):hover {
	border: none;
}

.btnSelected:global(.MuiButtonBase-root) {
	border-radius: 10px !important;
	background: #292841 !important;
	color: #fff;
}

.error {
	color: #ff4d4f;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0.1px;
	margin-top: 4px;
	margin-bottom: 0;
	text-align: start;
}

.btn:global(.MuiButtonBase-root):disabled {
	background-color: #1a1928;
	color: #3f3d56;
	cursor: not-allowed;
	border: 1px solid #3f3d56;
}

.btnSelected:global(.MuiButtonBase-root):disabled {
	background-color: #1a1928 !important;
	color: #3f3d56;
	cursor: not-allowed;
	border: 1px solid #3f3d56;
}
