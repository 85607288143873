@keyframes skeletonLoading {
	0% {
		background-position: -200% 0;
	}
	100% {
		background-position: 200% 0;
	}
}

.skeleton {
	background: linear-gradient(90deg, #0c0c17 25%, #1f1f3e 50%, #0c0c17 75%);
	background-size: 200% 100% !important;
	animation: skeletonLoading 3s infinite;
}
