.likeBtn {
	margin-top: auto;
	margin-bottom: 16px;
	display: flex;
	padding: 6px 8px;
	justify-content: center;
	align-items: center;
	gap: 4px;
	border-radius: 10px;
	border: 1px solid #5a5976;
	box-sizing: border-box;
	height: 34px;
}

.liked {
	border: 1px solid #9c85f6;
}

.count {
	color: var(--Dark-Text-Placeholder, #8b8aab);
	text-align: center;
	font-size: 13px;
	font-style: normal;
	font-weight: 700;
	line-height: 16px; /* 123.077% */
	letter-spacing: 0.2px;
}

.liked .count {
	color: #9c85f6;
}
