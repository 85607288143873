.scroll {
	overflow-x: scroll;
	min-height: 46px;
	margin-top: 32px;
	padding: 0;
	overflow-y: hidden;
}
.containerForScroll {
	position: relative;
}

.scroll::-webkit-scrollbar {
	width: 0px;
	height: 0px;
}

.container {
	display: flex;
}

.category {
	display: flex;
	padding: 10px 24px;
	align-items: center;
	gap: 10px;
	border-radius: 22px;
	background: #292841;
	color: #9c85f6;
	font-family: Inter;
	font-size: 15px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
	/* 160% */
	letter-spacing: 0.1px;
	cursor: pointer;
	border: none;
	margin-right: 14px;
	height: 44px;
	box-sizing: border-box;
	padding: 10px 24px;
	white-space: nowrap;
	text-decoration: none;
}

.active {
	border-radius: 22px;
	border: 2px solid #faff02;
	background: #212034;
	color: #faff02;
}

.container {
	display: flex;
}

.title {
	color: #fff;
	font-family: Inter;
	font-size: 40px;
	font-style: normal;
	font-weight: 900;
	line-height: 48px;
	/* 120% */
	letter-spacing: 0.374px;
	margin: 40px 0 0 0;
}

.scrollButton {
	position: absolute;
	width: 36px;
	height: 48px;
	z-index: 100;
	bottom: 0px;
	border-radius: 16px;
	background: #191827;
	color: #9c85f6;
	border: 2px solid #9c85f6;
	font-family: Inter;
	font-size: 15px;
	font-style: normal;
	cursor: pointer;
}

.scrollButtonLeft {
	left: 0px;
	box-shadow: 10px 0px 10px rgba(0, 0, 0, 0.2);
}

.scrollButtonLeft img {
	transform: rotate(90deg);
}

.scrollButtonRight {
	right: 0px;
	box-shadow: 10px 0px 10px rgba(0, 0, 0, 0.2);
}

.scrollButtonRight img {
	transform: rotate(-90deg);
}

.breadcrumbs {
	color: #5a5976;

	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 142.857% */
	letter-spacing: 0.374px;
	margin: 20px 0 0 0;
	display: flex;
	width: calc(100% - 20px);
}

.breadcrumbs a {
	color: #5a5976;
	text-decoration-line: underline;
	padding: 0;
}

.breadcrumbs p {
	margin: 0 10px;
	padding: 0;
}

@media screen and (max-width: 960px) {
	.title {
		margin-left: 20px;
		font-size: 28px;
		margin-top: 24px;
	}

	.scroll::-webkit-scrollbar {
		display: none;
	}

	.category:first-of-type {
		margin-left: 20px;
	}

	.category:last-of-type {
		margin-right: 40px;
	}

	.scroll {
		margin-top: 16px;
	}

	.breadcrumbs {
		margin: 20px 0 0 20px;
	}
}
