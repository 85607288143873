.header {
	background: linear-gradient(0deg, #292841 0%, #292841 100%);

	box-sizing: border-box;
	padding: 16px 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.row {
	display: flex;
	align-items: center;

	.inbutton {
		margin-left: 20px;
	}
}

@media screen and (min-width: 960px) {
	.search {
		margin-right: 20px;
	}
}

.search {
	cursor: pointer;
}

.logo {
	cursor: pointer;
}

.getApp:global(.inbutton) {
	border-radius: 12px;
	background: linear-gradient(270deg, #43c4f1 0%, #7b5df3 72.92%), #191827;
	padding: 10px 24px;
	backdrop-filter: blur(22px);
	box-sizing: border-box;
	height: 40px !important;
	width: 170px;
}

.signIn:global(.inbutton) {
	border-radius: 12px;
	padding: 10px 24px;
	box-sizing: border-box;
	height: 40px !important;
	width: 170px;
	margin-left: 8px;
}

.signInMobile:global(.inbutton) {
	border-radius: 12px;
	padding: 10px 24px;
	box-sizing: border-box;
	margin-left: 8px;
}

@media screen and (min-width: 960px) {
	.header {
		padding: 12px 24px;
	}
}

.searchRow {
	width: 360px;
	height: 40px;
	padding: 8px 16px;
	box-sizing: border-box;
	border-radius: 12px;
	background: rgba(0, 0, 0, 0.4);
	backdrop-filter: blur(22px);
	display: flex;
	align-items: center;
	justify-content: start;
	margin-right: 24px;
	cursor: text;
}

.searchText {
	color: #5a5976;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 125% */
}

.getAppImg {
	margin-right: 8px;
}
