.limit-modal__inner {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 410px;
	height: 270px;
	padding: 32px 40px;
	box-sizing: border-box;

	border-radius: 24px;
	background: linear-gradient(0deg, #212034 0%, #212034 100%), #f1eeff;
	color: white;
	align-items: center;
	overflow: hidden;

	display: flex;
	flex-direction: column;
}

.limit-modal__inner .modal__close {
	width: 24px;
	height: 24px;
}

.limit-modal__title {
	color: #fff;
	text-align: center;
	font-family: Inter;
	font-size: 28px;
	font-style: normal;
	font-weight: 900;
	line-height: 36px;
	/* 128.571% */
	letter-spacing: 0.374px;
	margin: 0;
}

.login-modal__btn.inbutton {
	font-family: Inter !important;
	border-radius: 40px;
	background: #5a34f0;
	width: 100%;
	margin-top: 30px;
}

.limit-modal__wait {
	color: #8a88ac;
	text-align: center;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	/* 137.5% */
	letter-spacing: 0.2px;
	margin-top: 24px;
	margin-bottom: 0;
}

.limit-modal__wait span {
	color: var(--Light-Text-Placeholder, #8a88ac);
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
	letter-spacing: 0.1px;
}

@media screen and (max-width: 450px) {
	.limit-modal__inner {
		width: calc(100dvw - 48px);
		padding: 32px 24px;
	}

	.limit-modal__title {
		font-size: 24px;
		line-height: 32px;
		letter-spacing: 0.32px;
		width: 80%;
	}
}
