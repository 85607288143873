.copy-text-area {
	display: flex;
	flex-direction: row;
	width: calc(100% - 2 * 20px);
	justify-content: flex-start;
	background-color: var(--input-color);
	border-radius: 16px;
	font-size: 20px;
	padding: 20px;
	position: relative;
}

.copy-text-area__name {
	margin: 0;
	color: var(--light-purp-color);
	margin-right: 14px;
	min-width: 7%;
}

.copy-text-area__value {
	margin: 0;
	word-break: break-all;
	width: 83%;
}

.copy-text-area__icon {
	margin: 0 0 0 auto;
	cursor: pointer;
	position: absolute;
	right: 30px;
	top: 20px;
}

.copy-text-area__code span {
	color: var(--main-color);
}

.copy-text-area__code p {
	margin: 0;
	width: 100%;
	font-size: 18px;
	margin-top: 8px;
}
