.dialog :global(.MuiPaper-root) {
	background: linear-gradient(0deg, #191827 0%, #191827 100%), #f1eeff;
	display: flex;
	flex-direction: column;
	font-family: 'Inter';
	align-items: center;
	color: white;
	text-align: center;
	width: 100%;
}

.dialog :global(.inbutton) {
	font-family: Inter;
	margin-top: 0 !important;
	margin: 0;
}

.container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
}

.content {
	padding: 20px;
	box-sizing: border-box;
	width: 100%;
}

.header {
	display: flex;
	width: 100%;
	justify-content: center;
	position: relative;
	background-color: #292841;
	padding: 20px 12px;
	box-sizing: border-box;
}

.back {
	margin-right: auto;
	cursor: pointer;
}

.title {
	color: #fff;
	font-family: Inter;
	font-size: 18px;
	font-weight: 900;
	line-height: 24px;
	letter-spacing: 0.37400001287460327px;
	text-align: center;
	margin: 0;
	margin-right: auto;
	margin-left: -20px;
}

.block {
	width: 100%;
	margin-bottom: 32px;
}
